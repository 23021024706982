import {
  DEVICE_LIST,
  CONNECTION_LIST,
  LOADING,
  DEVICE_TYPE_LIST,
  INTERFACES_LIST,
  REGION_LIST,
  REGION_CONNECTION_LIST,
  DEVICE_MONITOR_LIST,
  DEVICE_STATUS_LIST
} from "../constants/snmp";

const initial = {
  device_list: [],
  connection_list: [],
  device_type_list: [],
  interfaces_list: [],
  region_list : [],
  region_connection_list : [],
  loading: false,
  device_monitor_list : [],
  device_status_list : []
};

const snmpreducers = (state = initial, action) => {
  switch (action.type) {
    case DEVICE_LIST:
      return {
        ...state,
        device_list: action.device_list
      };
    case CONNECTION_LIST:
      return {
        ...state,
        connection_list: action.connection_list
      };
    case DEVICE_TYPE_LIST:
      return {
        ...state,
        device_type_list: action.device_type_list
      };
    case INTERFACES_LIST:
      return {
        ...state,
        interfaces_list: action.interfaces_list
      };
    case REGION_LIST:
      return {
        ...state,
          region_list : action.region_list
      }
    case LOADING:
      return {
        ...state,
        loading: action.isLoading
      };
    case REGION_CONNECTION_LIST:
      return{
        ...state,
        region_connection_list : action.region_connection_list
      }
    case DEVICE_MONITOR_LIST:
      return {
        ...state,
        device_monitor_list : action.device_monitor_list
      }
    case DEVICE_STATUS_LIST:
      return {
        ...state ,
          device_status_list : action.device_status_list
      }
    default:
      return state;
  }
};
export default snmpreducers;
