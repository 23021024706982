import {
  SOURCES_DOWNLINK,
  SOURCES_UPLINK,
  DESTINATIONS_DOWNLINK,
  DESTINATIONS_UPLINK,
  APPLICATIONS_DOWNLINK,
  APPLICATIONS_UPLINK,
  TOP_SOURCES,
  TOP_DESTINATIONS,
  TOP_APPLICATIONS,
  TOP_PORTS,
  DASHBOARD_LIVE_GRAPH,
  DASHBOARD_LIVE_MS_GRAPH,
  DASHBOARD_LIVE_GRAPH_DES,
  DASHBOARD_LIVE_MS_GRAPH_DES,
  DASHBOARD_LIVE_GRAPH_SRC,
  DASHBOARD_LIVE_MS_GRAPH_SRC,
  EVENTS,
  UPTIME,
  VLAN_LIST,
  LIVE_WATCH_STATE,
  DASHBOARD_INITIAL_LOADING,
  DASHBOARD_INITIAL_LOADING_MS,
  LIVE_PLOT_PAUSE,
  DASHBOARDLOADING
} from "../constants/dashboardTypes";

const initialState = {
  sou_down: null,
  sou_up: null,
  dash_live: null,
  dash_live_ms: null,
  dash_live_des: null,
  dash_live_ms_des: null,
  dash_live_src: null,
  dash_live_ms_src: null,
  des_down: null,
  des_up: null,
  app_down: null,
  app_up: null,
  top_sou: null,
  top_des: null,
  top_app: null,
  top_ports: null,
  vlan_list: [],
  events: [],
  uptime: new Date(),
  lwatch_state: true,
  dashboard_loading: false,
  pause: false,
  isLoading: false,

};

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case SOURCES_DOWNLINK:
      return {
        ...state,
        sou_down: action.sou_down,
      };
    case SOURCES_UPLINK:
      return {
        ...state,
        sou_up: action.sou_up,
      };
    case DASHBOARD_LIVE_GRAPH:
      return {
        ...state,
        dash_live: action.dash_live,
      };
    case DASHBOARD_INITIAL_LOADING:
      return {
        ...state,
        dashboard_loading: action.value,
      };
    case DASHBOARD_LIVE_MS_GRAPH:
      return {
        ...state,
        dash_live_ms: action.dash_live_ms,
      };
    case DASHBOARD_LIVE_GRAPH_SRC:
      return {
        ...state,
        dash_live_src: action.dash_live_src,
      };
    case DASHBOARD_LIVE_MS_GRAPH_SRC:
      return {
        ...state,
        dash_live_ms_src: action.dash_live_ms_src,
      };
    case DASHBOARD_LIVE_GRAPH_DES:
      return {
        ...state,
        dash_live_des: action.dash_live_des,
      };
    case DASHBOARD_LIVE_MS_GRAPH_DES:
      return {
        ...state,
        dash_live_ms_des: action.dash_live_ms_des,
      };
    case DESTINATIONS_DOWNLINK:
      return {
        ...state,
        des_down: action.des_down,
      };
    case DESTINATIONS_UPLINK:
      return {
        ...state,
        des_up: action.des_up,
      };
    case APPLICATIONS_DOWNLINK:
      return {
        ...state,
        app_down: action.app_down,
      };
    case APPLICATIONS_UPLINK:
      return {
        ...state,
        app_up: action.app_up,
      };
    case TOP_SOURCES:
      return {
        ...state,
        top_sou: action.top_sou,
      };
    case TOP_DESTINATIONS:
      return {
        ...state,
        top_des: action.top_des,
      };
    case TOP_APPLICATIONS:
      return {
        ...state,
        top_app: action.top_app,
      };
    case TOP_PORTS:
      return {
        ...state,
        top_ports: action.top_ports,
      };
    case EVENTS:
      return {
        ...state,
        events: action.events,
      };
    case VLAN_LIST:
      return {
        ...state,
        vlan_list: action.vlan_list,
      };
    case UPTIME:
      return {
        ...state,
        uptime: action.uptime,
      };
    case LIVE_WATCH_STATE:
      return {
        ...state,
        lwatch_state: action.lwatch_state,
      };
    case LIVE_PLOT_PAUSE:
      return {
        ...state,
        pause: action.pause
      };
      case DASHBOARDLOADING:
        return {
          ...state,
          isLoading: action.isLoading
        };
    default:
      return state;
  }
};

export default dashboard;
