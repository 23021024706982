import {
  ERROR_MSG_POPUP
  } from '../constants/errormsgPopup';

const initialState = {
  disconnect : false
}

const error_msg_popup = (state = initialState, action) => {
  switch (action.type) {
      case ERROR_MSG_POPUP:
          return {
            ...state,
              disconnect: action.disconnect
          };

          default:
          return state;
  }
};
export default error_msg_popup;

