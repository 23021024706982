export const SESSION_DATA = 'SESSION_DATA';
export const SESSION_DATA_LOADING = 'SESSION_DATA_LOADING';
export const SOURCES_DATA = 'SOURCES_DATA';
export const SOURCES_DATA_LOADING = 'SOURCES_DATA_LOADING';
export const DESTINATIONS_DATA = 'DESTINATIONS_DATA';
export const DESTINATIONS_DATA_LOADING = 'DESTINATIONS_DATA_LOADING';
export const APPLICATIONS_DATA = 'APPLICATIONS_DATA';
export const APPLICATIONS_DATA_LOADING = 'APPLICATIONS_DATA_LOADING';
export const SERVICES_DATA = 'SERVICES_DATA';
export const SERVICES_DATA_LOADING = 'SERVICES_DATA_LOADING';
export const RESPONSE_NULL = 'RESPONSE_NULL';
export const SOURCES_PIE = 'SOURCES_PIE';
export const DESTINATIONS_PIE = 'DESTINATIONS_PIE';
export const APPLICATIONS_PIE = 'APPLICATIONS_PIE';
export const SERVICES_PIE = 'SERVICES_PIE';
export const SOURCE_DIAGRAM = 'SOURCE_DIAGRAM';
export const DESTINATION_DIAGRAM = 'DESTINATION_DIAGRAM';
export const APPLICATION_DIAGRAM = 'APPLICATION_DIAGRAM';
export const SERVICE_DIAGRAM = 'SERVICE_DIAGRAM';
export const RULE_ID = 'RULE_ID';
