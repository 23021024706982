import {
    NETFLOW
  } from '../constants/netflow';

  
const initialState = {
    netflowData : []
};

const netflow = (state = initialState, action) => {
    switch (action.type) {
        case NETFLOW:
          return {
            ...state,
            netflowData: action.netflow
          };
        default:
          return state;
    }
};

export default netflow;