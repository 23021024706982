import {
    APPLICATION_PROFILE_OCCURENCES,
    PIPE_OCCURENCES,
    SHED_OCCURENCES,
    ADDRESS_OCCURENCES,
    ADDRESS_PROFILE_OCCURENCES,
    CUSTOM_SIGNATURE_OCCURENCES,
    CUSTOM_SIGNATURE_CATEGORY_OCCURENCES,
    QUOTA_PROFILE_OCCURENCES
  } from '../constants/occurencesType';

  
const initialState = {
    occurences_app_profile : [],
    count_app_profile:0,
    occurences_pipe : [],
    count_pipe:0,
    occurences_shed : [],
    count_shed:0,
    occurences_address : [],
    count_address:0,
    occurences_address_profile : [],
    count_address_profile:0,
    occurences_custom_signature : [],
    count_custom_signature:0,
    occurences_custom_signature_category : [],
    count_custom_signature_category:0,
    occurences_quota_profile : [],
    count_quota_profile:0
};

const occurences = (state = initialState, action) => {
    switch (action.type) {
        case APPLICATION_PROFILE_OCCURENCES:
          return {
            ...state,
            occurences_app_profile: action.occurences.rows,
            count_app_profile:action.occurences.count
          };
        case PIPE_OCCURENCES:
        return {
            ...state,
            occurences_pipe: action.occurences.rows,
            count_pipe:action.occurences.count
        };
        case SHED_OCCURENCES:
        return {
            ...state,
            occurences_shed: action.occurences.rows,
            count_shed:action.occurences.count
        };
        case ADDRESS_OCCURENCES:
        return {
            ...state,
            occurences_address: action.occurences.rows,
            count_address:action.occurences.count
        };
        case ADDRESS_PROFILE_OCCURENCES:
        return {
            ...state,
            occurences_address_profile: action.occurences.rows,
            count_address_profile:action.occurences.count
        };
        case CUSTOM_SIGNATURE_OCCURENCES:
        return {
            ...state,
            occurences_custom_signature: action.occurences.rows,
            count_custom_signature:action.occurences.count
        };
        case CUSTOM_SIGNATURE_CATEGORY_OCCURENCES:
        return {
            ...state,
            occurences_custom_signature_category: action.occurences.rows,
            count_custom_signature_category:action.occurences.count
        };
        case QUOTA_PROFILE_OCCURENCES:
        return {
            ...state,
            occurences_quota_profile: action.occurences.rows,
            count_quota_profile:action.occurences.count
        };
        default:
        
          return state;
    }
};

export default occurences;