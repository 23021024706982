import {FETCH_QUOTA_PROFILE_DATA_FAILURE,
FETCH_QUOTA_PROFILE_DATA_REQUEST,
FETCH_QUOTA_PROFILE_DATA_SUCCESS,
FETCH_SCHEDULE_DATA_SUCCESS,
FETCH_SHAPER_DATA_SUCCESS} from '../constants/quotaProfile'



const initialState = {
    loading: false,
    quotaProfile: [],
    error : '',
    scheduleData : [],
    shaperData : []
}

const quotaProfileReducers = (state = initialState , action) =>{

    if(action.type === FETCH_QUOTA_PROFILE_DATA_REQUEST){
        return{
            ...state,
            loading : true

        }
    }else if(action.type === FETCH_QUOTA_PROFILE_DATA_SUCCESS){
        return {
            ...state,
            loading : false,
            quotaProfile: action.payload,
            error : ''
        }
    }else if (action.type === FETCH_QUOTA_PROFILE_DATA_FAILURE){
        return {
            ...state,
            loading : false,
            quotaProfile: [],
            error : action.payload
        }
    }else if(action.type === FETCH_SCHEDULE_DATA_SUCCESS){
        return {
            ...state,
            scheduleData : action.payload
        }
    }else if(action.type === FETCH_SHAPER_DATA_SUCCESS){
        return{
            ...state,
            shaperData : action.payload
        }
    }else return state

}


export default quotaProfileReducers