import {
  SOURCE_TYPE,
  SOURCE_VAL,
  DESTINATION_TYPE,
  DESTINATION_VAL,
  SCHEDULE_VAL,
  URL_VAL,
  APPLICATION_VAL,
  SERVICE_VAL,
  ACTION_VAL
} from '../constants/wizard';

const initialState = {
  sou_type: 0,
  sou_val : 0,
  des_type : 0,
  des_val : 0,
  sch_val : 0,
  url_val : 0,
  app_val : 0,
  ser_val : 0,
  act_val : 0

};

const wizard = (state = initialState, action) => {
  switch (action.type) {
      case SOURCE_TYPE:
          return {
              ...state,
              sou_type: action.val
          };
      case SOURCE_VAL:
          return {
              ...state,
              sou_val: action.val_
          };
      case DESTINATION_TYPE:
              return {
                  ...state,
                  des_type: action.val
              };
      case DESTINATION_VAL:
              return {
                  ...state,
                  des_val: action.val
              };

      case SCHEDULE_VAL:
              return {
                  ...state,
                  sch_val: action.val
          };
      case URL_VAL:
              return {
                  ...state,
                  url_val: action.val
          };
      case APPLICATION_VAL:
              return {
                  ...state,
                  app_val: action.val
          };

      case SERVICE_VAL:
              return {
                  ...state,
                  ser_val: action.val
          };

      case ACTION_VAL:
              return {
                  ...state,
                  act_val: action.val
          };


      default:
          return state;
  }
};

export default wizard;
