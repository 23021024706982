import axios from 'axios';
import * as types from '../constants/userData';
import { USER_TYPE ,USER_TYPE_LOGIN} from '../constants/userData';
import { BASE_URL } from '../constants/baseURL';
import $ from 'jquery';
import * as activityLogTypes from 'constants/activityTypes';
import { addLogWithoutDispatch } from 'actions/activityActions';

export const get_users = () => {
  return dispatch => {
    axios
      .get(BASE_URL + 'api/user', { withCredentials: true })
      .then(res => {
        dispatch(set_users(res.data));
        if (res.data !== undefined && res.data.length > 0 && res.data[0].perm_type !== undefined) {
          axios.post(BASE_URL + 'api/user/perm', $.param({
            'id': res.data[0].perm_type
          }), { withCredentials: true })
            .then(res => {            
              if (res.data !== undefined && res.data.length > 0 && res.data[0].priviledges !== undefined) {
                dispatch(set_users_types(res.data[0].priviledges.split(" ")));
              }
            })
            .catch(err => {
              console.log(err);
            });

        }

      })
      .catch(err => {
        console.log(err);
      });
    setTimeout(() => {
    }, 50);
  };
};

export const loginperm = () => {
  
  return dispatch => {
    axios
      .get(BASE_URL + 'api/uinfo', { withCredentials: true })
      .then(res => {
if (res.data !== undefined && res.data.length > 0 && res.data[0].perm_type !== undefined) {
  axios.post(BASE_URL + 'api/user/perm', $.param({
    'id': res.data[0].perm_type
  }), { withCredentials: true })
    .then(res => {   
      if (res.data !== undefined && res.data.length > 0 && res.data[0].priviledges !== undefined) {
        dispatch(set_users_typeslogin(res.data[0].priviledges.split(" ")));
      }
    })
    .catch(err => {
      console.log(err);
    });

}
      })
      .catch(err => {
        console.log(err);
      });
  };
};

export const get_custom_user_type = () => {
  
  return dispatch => {
    axios
      .get(BASE_URL + 'api/custom/user/type', { withCredentials: true })
      .then(res => {

        dispatch(custom_user_type(res.data));
      })
      .catch(err => {
        console.log(err);
      });
  };
};

export const enable_disable_user = (username, name, id, email, state) => {
  return dispatch => {
    axios
      .put(BASE_URL + 'api/user/enableDisableAcc', $.param({
        'id': id,
        'email': email,
        'state': state
      }), { withCredentials: true })
      .then(res => {
      
        dispatch(set_snack_bar(true, 10));
        addLogWithoutDispatch(username, name + " user '" + email + "'")
        dispatch(get_users(res.data));

      })
      .catch(err => {
        dispatch(set_snack_bar(true, 2));
        console.log(err);
      });
  };
};

export const add_user = (username, type, perm_type, email, psw, bw) => {
  return dispatch => {
    axios
      .post(BASE_URL + 'api/user', $.param({
        type: parseInt(type),
        perm_type: parseInt(perm_type),
        is_enable: 1,
        email: email,
        psw: psw,
        bandwidth: parseInt(0)
      }), { withCredentials: true })
      .then(res => {
        addLogWithoutDispatch(username, "Created user '" + email + "'")
        dispatch(set_snack_bar(true, 23));
        dispatch(get_users(res.data));

      })
      .catch(err => {
        dispatch(set_snack_bar(true, 2));
        console.log(err);
      });
  };
};

export const update_user = (username, key, email, bw, email_name, changed_bandwidth, no_Change) => {
  return dispatch => {
    axios
      .put(BASE_URL + 'api/user', $.param({
        user_id: key,
        email: email,
        bandwidth: parseInt(bw)
      }), { withCredentials: true })
      .then(res => {
        if (no_Change === 0) {
          addLogWithoutDispatch(username, "Updated user '" + email + "' " + email_name + changed_bandwidth)
        }
       
        dispatch(set_snack_bar(true, 22));
        dispatch(get_users(res.data));


      })
      .catch(err => {
        dispatch(set_snack_bar(true, 2));
        
      });
  };
};

export const reset_pswd = (username, name, key, new_pwd,old_pswd) => {
  return dispatch => {
    axios
      .put(BASE_URL + 'api/user/password', $.param({
        id: key,
        oldpswd: '',
        newpswd: new_pwd,
        oldpswd : old_pswd

      }), { withCredentials: true })
      .then(res => {
        addLogWithoutDispatch(username, "Reset Password of user '" + name + "'")       
        dispatch(set_snack_bar(true, 10));
        dispatch(get_users(res.data));

      })
      .catch(err => {
        dispatch(set_snack_bar(true, 2));
        
      });
  };
};

export const delete_user = (username, key, email) => {
  
  return dispatch => {
    axios
      .delete(BASE_URL + 'api/user/' + key + '/' + email, { withCredentials: true })
      .then(res => {
        addLogWithoutDispatch(username, "Deleted user '" + email + "'")
        dispatch(set_snack_bar(true, 24));
        dispatch(get_users(res.data));
      })
      .catch(err => {
        dispatch(set_snack_bar(true, 2));
        console.log(err);
      });
  };
};

export const get_user_ip = (userid) => {
  return dispatch => {
    axios
      .get(BASE_URL + 'api/user/ip/' + userid, { withCredentials: true })
      .then(res => {
        
        dispatch(get_ip_list(res.data));

      })
      .catch(err => {
        console.log(err);
      });
  };
};

export const add_profile_ip = (user_id, ipv, ip, mask) => {
  return dispatch => {
    axios
      .post(BASE_URL + 'api/user/ip', $.param({
        user_id: user_id,
        ip_version: ipv,
        ip: ip,
        mask: mask
      }), { withCredentials: true })
      .then(res => {
       
        dispatch(set_snack_bar(true, 23));
        dispatch(get_user_ip(user_id));

      })
      .catch(err => {
        dispatch(set_snack_bar(true, 2));
        console.log(err);
      });
  };
};

export const update_profile_ip = (user_id, key, ipv, ip, mask) => {
  return dispatch => {
    axios
      .put(BASE_URL + 'api/user/ip', $.param({
        user_id: user_id,
        list_id: key,
        ip_version: ipv,
        ip: ip,
        mask: mask
      }), { withCredentials: true })
      .then(res => {
        
        dispatch(set_snack_bar(true, 22));
        dispatch(get_user_ip(user_id));

      })
      .catch(err => {
        dispatch(set_snack_bar(true, 2));
        console.log(err);
      });
  };
};

export const delete_profile_ip = (user_id, key) => {
  return dispatch => {
    axios
      .delete(BASE_URL + 'api/user/ip/' + user_id + '/' + key, { withCredentials: true })
      .then(res => {
        
        dispatch(set_snack_bar(true, 24));
        dispatch(get_user_ip(user_id));

      })
      .catch(err => {
        dispatch(set_snack_bar(true, 2));
        console.log(err);
      });
  };
};

export function set_users(table_data) {
  return { type: types.USERS_DATA, table_data };
}
export function set_users_types(types) {
  return { type: USER_TYPE, types };
}
export function set_users_typeslogin(types) { 
  return { type: USER_TYPE_LOGIN, types };
}
export function select_user(userid) {
  return { type: types.SELECT_USER, userid };
}

export function custom_user_type(customtypes) {
  return { type: types.CUSTOM_USER_TYPE, customtypes };
}
export function set_snack_bar(open, stype) {
  return { type: types.SNACK_BAR, open, stype };
}
export function get_ip_list(iplist) {
  return { type: types.SELECT_USER_IPS, iplist };
}
