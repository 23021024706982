import {
    LOGS
  } from '../constants/logActionTypes';

  
const initialState = {
    logs : []
};

const logs = (state = initialState, action) => {
    switch (action.type) {
        case LOGS:
          return {
            ...state,
            logs: action.logs
          };
        default:
          return state;
    }
};

export default logs;