import {FETCH_EMAIL_CONFIGS,
        FETCH_SMS_CONFIGS,FETCH_MODEM_LIST} from '../constants/configTypes'



const initialState = {
    emailConfig :[],
    smsConfig : [],
    modemlist:[]
}

const configReducers = (state = initialState,action)=>{

    if(action.type ===FETCH_EMAIL_CONFIGS){
        return{
            ...state,
            emailConfig : action.payload
        }
    }else if(action.type=== FETCH_SMS_CONFIGS){
        return {
            ...state,
            smsConfig : action.payload
        }
    }
    else if(action.type=== FETCH_MODEM_LIST){
        return {
            ...state,
            modemlist : action.payload
        }
    }
    else{
        return state
    }

}

export default configReducers