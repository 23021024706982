import {
    BILLING
  } from '../constants/settingsType';

  
const initialState = {
    billing : []
};

const billing = (state = initialState, action) => {
    switch (action.type) {
        case BILLING:
          return {
            ...state,
            billing: action.billing
          };
        default:
          return state;
    }
};

export default billing;