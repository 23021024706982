import * as types from "../constants/reportEmailProfileTypes";

const initialstate = {
  emailProfileData:[],
  emailProfileItemData:[],
}

const reportEmailProfile = (state = initialstate, action) => {

  // console.log("reducer state",state);
  
  switch(action.type){
    case types.GETEMAILPROFILEDATA:
      // console.log("config reducer payload",action);
      return  {
        ...state,
        emailProfileData : action.payload
      }
    case types.GETEMAILPROFILEITEMDATA:
      return {
        ...state,
        emailProfileItemData : action.payload
      }
    default:
      return state;
  }
}

export default reportEmailProfile;