import { APP_SIG_CAT,RULE_APP_SIG_CAT } from '../constants/get_app_sig_cat_list_constants';

const initial = {app_sig_cat: [],rule_app_sig_cat: []};

const get_app_sig_cat_list_reducers = (state = initial, action) => {
    switch (action.type) {
      case APP_SIG_CAT:
        return {
          ...state,
          app_sig_cat: action.app_sig_cat
        };
         case RULE_APP_SIG_CAT:
        return {
          ...state,
          rule_app_sig_cat: action.app_sig_cat
        };
      default:
        return state;
    }
  };
  
  export default get_app_sig_cat_list_reducers;