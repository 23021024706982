export const TOGGLE_BOXED_LAYOUT = 'TOGGLE_BOXED_LAYOUT';
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSED_NAV';
export const TOGGLE_NAV_BEHIND = 'TOGGLE_NAV_BEHIND';
export const TOGGLE_FIXED_HEADER = 'TOGGLE_FIXED_HEADER';
export const CHANGE_SIDEBAR_WIDTH = 'CHANGE_SIDEBAR_WIDTH';
export const CHANGE_COLOR_OPTION = 'CHANGE_COLOR_OPTION';
export const CHANGE_THEME = 'CHANGE_THEME';


export const SESSION_FILTER_CHIPDATA = 'SESSION_FILTER_CHIPDATA';
export const SESSION_FILTER_CHECKED = 'SESSION_FILTER_CHECKED';
export const SESSION_FILTER_NEWVAL = 'SESSION_FILTER_NEWVAL';
export const SESSION_FILTER_INPUTVAL = 'SESSION_FILTER_INPUTVAL';

export const SUMMARY_NAV_STARTDATE = 'SUMMARY_NAV_STARTDATE';
export const SUMMARY_NAV_SELECTEDDATE = 'SUMMARY_NAV_SELECTEDDATE';
export const SUMMARY_NAV_SELECTEDDURATION = 'SUMMARY_NAV_SELECTEDDURATION';


