import React from 'react';
import { render } from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import configureStore, { history } from './store/configureStore';
import Root from './components/Root';
import * as serviceWorker from './serviceWorker';
import { ErrorBoundary } from 'react-error-boundary';
import QueueAnim from 'rc-queue-anim';


const store = configureStore();


render(

  <AppContainer >
      <ErrorBoundary
    FallbackComponent={ErrorFallback}
    onReset={() => {
      // reset the state of your app so the error doesn't happen again
    }}
  >
  
    <Root store={store} history={history} />


    </ErrorBoundary>

   </AppContainer>,
  //  </ErrorBoundary>,
  document.getElementById('root')
);

if (module.hot) {
  module.hot.accept('./components/Root', () => {
    const NewRoot = require('./components/Root').default;
    render(
      <AppContainer>
        <ErrorBoundary
    FallbackComponent={ErrorFallback}
    onReset={() => {
      // reset the state of your app so the error doesn't happen again
    }}
  >
        <NewRoot store={store} history={history} />
        </ErrorBoundary>
       </AppContainer>,
      document.getElementById('root')
    );
  });
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();


function ErrorFallback({error, resetErrorBoundary}) {
  return (
    <>
    <Page/>
    <h4> {error.message} </h4>
    </>
  )
}

const Error404 = () => (
  <div className="err-container text-center">
    <div className="err">
      <h1>Error</h1>
      <h2>Something Went Wrong</h2>
     
    </div>

    <div className="err-body">
    </div>
  </div>
);

const Page = () => (
  <div className="container-fluid no-breadcrumb">
  <div className="page-err">
    <QueueAnim type="bottom" className="ui-animate">
      <div key="1">
        <Error404 />
      </div>
    </QueueAnim>
  </div>
  </div>
);
