export const APPWATCH_PROFILE = 'APPWATCH_PROFILE';
export const APPWATCH_PROFILE_ITEM = 'APPWATCH_PROFILE_ITEM';
export const SELECT_LINKUTIL_PROF = 'SELECT_LINKUTIL_PROF';
export const SELECT_LINKUTIL_PROF_ITEM = 'SELECT_LINKUTIL_PROF_ITEM';
export const SELECT_LINKUTIL_APP_CLR = 'SELECT_LINKUTIL_APP_CLR';
export const UPLINK_SERIES = "UPLINK_SERIES";
export const DOWNLINK_SERIES = "DOWNLINK_SERIES";
export const PRV_APPWATCH_PROFILE_ITEM = "PRV_APPWATCH_PROFILE_ITEM";
export const UPLINK_DOWNLINK_SERIES = "UPLINK_DOWNLINK_SERIES";
export const APP_SERVICE_SIG_LIST = "APP_SERVICE_SIG_LIST";
export const LOADING_SUBNET = "LOADING_SUBNET";
