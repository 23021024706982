import {
    STATUS,
  } from '../constants/licenseConstant';

  
const initialState = {
    status : 0
};

const status = (state = initialState, action) => {
    switch (action.type) {
        case STATUS:
          return {
            ...state,
            status: action.status
          };
        default:
          return state;
    }
};

export default status;