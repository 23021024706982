import {
    BNW_HIS_DOWN,
    BNW_HIS_UP,
    USG_REPORT,
    HEADERTIME,
    TYPE
} from '../constants/history';


const initialState = {
    downlink: [],
    uplink: [],
    usagereports: [],
    header: '',
    bnd_type: 0
};

const history = (state = initialState, action) => {
    switch (action.type) {
        case BNW_HIS_DOWN:
            return {
                ...state,
                downlink: action.downlink
            };
        case BNW_HIS_UP:
            return {
                ...state,
                uplink: action.uplink
            };
        case USG_REPORT:
            return {
                ...state,
                usagereports: action.usagereports
            }
        case HEADERTIME:
            return {
                ...state,
                header: action.header
            }
        case TYPE:
            return {
                ...state,
                bnd_type: action.bnd_type
            }
        default:
            return state;
    }
};

export default history;