import {
  DEFAULTSHAPER,
  ADMINPIPES,
  USERPIPES,
  SCHEDULES,
  SOURCE_RULES,
  DESTINATION_RULES,
  URL_RULES,
  APP_RULES,
  SERVICE_RULES,
  PIPES,
  PIPESCHEDULE,
  URL_LIST,
  URL_PROFILE,
  APP_LIST_RULE,
  APP_PROFILE,
  APP_PROFILE_NEW,
  APP_PROFILE_ITEM,
  SRV_LIST,
  SRV_PROFILE,
  ADRS_IP,
  ADRS_MAC,
  ADRS_REMOTEUSERS,
  ADRS_REMOTEGROUPS,
  ADDRESSPROFILE,
  RADIUS,
  RADIUS_USERS,
  RADIUS_GROUPS,
  AD_USERS,
  AD_GROUPS,
  DHCP_USERS,
  DHCP_GROUPS,
  ADDRESSPROFILE_ADDRESS,
  RULE_ADDITION,
  RULE_ADDITION_TABLE,
  RULE_UPDATE,
  PIPE_STAT,
  PIPE_STAT_G_S,
  PIPE_STAT_G_MS,
  LOADING,
  RULE_LIST,
  GROUPUSERS,
  SELECTEDADDRESSPROFILE,
} from "../constants/rulesTypes";

const initial = {
  defaultpipes: [],
  adminpipes: [],
  userpipes: [],
  schedules: null,
  sources: [],
  destinations: [],
  urls: [],
  apps: [],
  services: [],
  pipes: null,
  pipeschedules: [],
  urllist: [],
  urlprofile: [],
  applist: [],
  appprofile: [],
  appprofilenew: null,
  appprofileitem: [],
  srvlist: [],
  srvprofile: [],
  adr_ip: null,
  adr_mac: null,
  adr_remoteusers: null,
  adr_remotegroups: null,
  addressprofile: null,
  selectedAddressProfile: [],
  addressprofileaddress: [],
  radiususers: [],
  radiusgroups: [],
  adusers: [],
  adcount: 0,
  adgroups: [],
  adgroupscount: 0,
  dhcpusers: [],
  dhcpgroups: [],
  ruleaddition: [],
  rulelist: [],
  count: 0,
  ruleupdate: [],
  pipestat: [],
  statcount: 0,
  pipestat_g_s: [],
  pipestat_g_ms: { up: [], down: [] },
  loading: false,
  groupusers: [],
};

const rules = (state = initial, action) => {
  switch (action.type) {
    case DEFAULTSHAPER:
      return {
        ...state,
        defaultpipes: action.defaultpipes,
      };
    case ADMINPIPES:
      return {
        ...state,
        adminpipes: action.adminpipes,
      };
    case USERPIPES:
      return {
        ...state,
        userpipes: action.userpipes,
      };
    case SCHEDULES:
      return {
        ...state,
        schedules: action.schedules,
      };
    case SOURCE_RULES:
      return {
        ...state,
        sources: action.sources,
      };
    case DESTINATION_RULES:
      return {
        ...state,
        destinations: action.destinations,
      };
    case URL_RULES:
      return {
        ...state,
        urls: action.urls,
      };
    case APP_RULES:
      return {
        ...state,
        apps: action.apps,
      };
    case SERVICE_RULES:
      return {
        ...state,
        services: action.services,
      };
    case PIPES:
      return {
        ...state,
        pipes: action.pipes,
      };
    case PIPESCHEDULE:
      return {
        ...state,
        pipeschedules: action.pipeschedules,
      };
    case URL_LIST:
      return {
        ...state,
        urllist: action.urllist,
      };
    case URL_PROFILE:
      return {
        ...state,
        urlprofile: action.urlprofile,
      };
    case APP_LIST_RULE:
      return {
        ...state,
        applist: action.applist,
      };
    case APP_PROFILE:
      return {
        ...state,
        appprofile: action.appprofile,
      };
    case APP_PROFILE_NEW:
      return {
        ...state,
        appprofilenew: action.appprofilenew,
      };
    case APP_PROFILE_ITEM:
      return {
        ...state,
        appprofileitem: action.appprofileitem,
      };
    case SRV_LIST:
      return {
        ...state,
        srvlist: action.srvlist,
      };
    case SRV_PROFILE:
      return {
        ...state,
        srvprofile: action.srvprofile,
      };
    case ADRS_IP:
      return {
        ...state,
        adr_ip: action.adr_ip,
      };
    case ADRS_MAC:
      return {
        ...state,
        adr_mac: action.adr_mac,
      };
    case ADRS_REMOTEUSERS:
      return {
        ...state,
        adr_remoteusers: action.adr_remoteusers,
      };
    case ADRS_REMOTEGROUPS:
      return {
        ...state,
        adr_remotegroups: action.adr_remotegroups,
      };
    case ADDRESSPROFILE:
      return {
        ...state,
        addressprofile: action.addressprofile,
      };
    case SELECTEDADDRESSPROFILE:
      return {
        ...state,
        selectedAddressProfile: action.selectedAddressProfile,
      };
    case ADDRESSPROFILE_ADDRESS:
      return {
        ...state,
        addressprofileaddress: action.addressprofileaddress,
      };
    case AD_USERS:
      return {
        ...state,
        adusers: action.adusers.rows,
        adcount: action.adusers.count.count,
      };
    case AD_GROUPS:
      return {
        ...state,
        adgroups: action.adgroups.rows,
        adgroupscount: action.adgroups.count.count,
      };
    case DHCP_USERS:
      return {
        ...state,
        dhcpusers: action.dhcpusers,
      };
    case RADIUS_USERS:
      return {
        ...state,
        radiususers: action.radiususers,
      };
    case RADIUS_GROUPS:
      return {
        ...state,
        radiusgroups: action.radiusgroups,
      };
    case RULE_ADDITION:
      return {
        ...state,
        ruleaddition: action.ruleaddition,
      };
    case RULE_LIST:
      return {
        ...state,
        rulelist: action.rulelist.rows,
        count: action.rulelist.count,
      };
    case RULE_UPDATE:
      return {
        ...state,
        ruleupdate: action.ruleupdate,
      };
    case PIPE_STAT:
      return {
        ...state,
        pipestat: action.pipestat.rows,
        statcount: action.pipestat.count,
      };
    case PIPE_STAT_G_S:
      return {
        ...state,
        pipestat_g_s: action.pipestat_g_s,
      };
    case PIPE_STAT_G_MS:
      return {
        ...state,
        pipestat_g_ms: action.pipestat_g_ms,
      };
    case GROUPUSERS:
      return {
        ...state,
        groupusers: action.users,
      };
    case LOADING:
      return {
        ...state,
        loading: action.loading,
      };

    default:
      return state;
  }
};

export default rules;
