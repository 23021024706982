import { combineReducers } from "redux";
import { connectRouter } from 'connected-react-router'
import settings from './settings';
import session_filter from './session_filter';
import login from './login';
import session from './session';
import users from './users';
import appNserv from './appsrvs';
import live from './live_watch';
import linkutil from './linkutil';
import rules from './rules';
import billing from './billing';
import management from './management';
import hasy from './highavailablity';
import traffic from './trafficdiagram';
import dashboard from './dashboard';
import customusertypes from './customusertypes';
import history from './history';
import report from './report';
import summary_navbar from './summary_navbar';
import ldap from './ldap';
import dhcp from './dhcp';
import radius_server from './radiusserver';
import quotaReducers from './quotaReducers'
import quotaProfiles from './quotaProfileReducers'
import quotaObjShedules from './quotaObjSchedulesReducers'
import ipusage from './ipstatReducers'
import device_mon from './device_mon_redeucers'
import config from './configReducers'
import wizard from './wizard'
import disconnectPopupmsg from './disconnectPopupmsg'
import customSignature from './customSignature'
import logs from "./activityLogs"
import sqi from "./sqi"
import snmpreducers from "./snmpreducers";
import get_app_sig_cat_list_reducers from "./get_app_sig_cat_list_reducers"
import licenseReducer from "./licenseReducer"
import maintenance from "./maintenanceReducers";
import reportConfig from "./reportConfigReducer"
import reportFilter from "./reportFilterReducer"
import reportEmailProfile from "./reportEmailProfileReducer"
import netflow from "./netflow";
import occurences from "./occurencesReducers";

const createRootReducer = (history_) => combineReducers({
  router : connectRouter(history_),
  settings,
  session_filter,
  login,
  session,
  users,
  appNserv,
  live,
  linkutil,
  rules,
  billing,
  management,
  hasy,
  traffic,
  dashboard,
  customusertypes,
  history,
  report,
  summary_navbar,
  ldap,
  dhcp,
  radius_server,
  quotaReducers,
  quotaProfiles,
  quotaObjShedules,
  ipusage,
  device_mon,
  config,
  wizard,
  disconnectPopupmsg,
  customSignature,
  logs,
  snmpreducers,
  sqi,
  get_app_sig_cat_list_reducers,
  licenseReducer,
  maintenance,
  reportConfig,
  reportFilter,
  reportEmailProfile,
  netflow,
  occurences
});

export default (createRootReducer);
