export const DEFAULTSHAPER = "DEFAULTSHAPER";
export const ADMINPIPES = "ADMINPIPES";
export const USERPIPES = "USERPIPES";
export const SCHEDULES = "SCHEDULES";

export const SOURCE_RULES = "SOURCE_RULES";
export const DESTINATION_RULES = "DESTINATION_RULES";
export const URL_RULES = "URL_RULES";
export const APP_RULES = "APP_RULES";
export const SERVICE_RULES = "SERVICE_RULES";

export const PIPES = "PIPES";
export const PIPESCHEDULE = "PIPESCHEDULE";
export const URL_LIST = "URL_LIST";
export const URL_PROFILE = "URL_PROFILE";
export const APP_LIST_RULE = "APP_LIST_RULE";
export const APP_PROFILE = "APP_PROFILE";

//app prof new
export const APP_PROFILE_NEW = "APP_PROFILE_NEW";
export const APP_PROFILE_ITEM = "APP_PROFILE_ITEM";

export const SRV_LIST = "SRV_LIST";
export const SRV_PROFILE = "SRV_PROFILE";
export const ADRS_IP = "ADRS_IP";
export const ADRS_MAC = "ADRS_MAC";
export const ADRS_REMOTEUSERS = "ADRS_REMOTEUSERS";
export const ADRS_REMOTEGROUPS = "ADRS_REMOTEGROUPS";

export const ADDRESSPROFILE = "ADDRESSPROFILE";
export const SELECTEDADDRESSPROFILE = "SELECTEDADDRESSPROFILE";
export const ADDRESSPROFILE_ADDRESS = "ADDRESSPROFILE_ADDRESS";

export const RADIUS_USERS = "RADIUS_USERS";
export const RADIUS_GROUPS = "RADIUS_GROUPS";

export const AD_USERS = "AD_USERS";
export const AD_GROUPS = "AD_GROUPS";

export const DHCP_USERS = "DHCP_USERS";
export const DHCP_GROUPS = "DHCP_GROUPS";

export const RULE_ADDITION = "RULE_ADDITION";
export const RULE_LIST = "RULE_LIST";
export const RULE_ADDITION_TABLE = "RULE_ADDITION_TABLE";

export const RULE_UPDATE = "RULE_UPDATE";

export const PIPE_STAT = "PIPE_STAT";
export const PIPE_STAT_G_S = "PIPE_STAT_G_S";
export const PIPE_STAT_G_MS = "PIPE_STAT_G_MS";
export const LOADING = "LOADING";
export const GROUPUSERS = "GROUPUSERS";
