import {FETCH_QTA_OBJ_SHEDULE_DATA_FAILURE,
        FETCH_QTA_OBJ_SHEDULE_DATA_SUCCESS,
        FETCH_QTA_OBJ_SHEDULE_DATA_REQUEST } from '../constants/quota'


const initialState = {
    quotaSchduleData :[],
    loading : false,
    error : ""
}

const QuoataScheduleReducers= (state = initialState , action)=>{
    if(action.type==FETCH_QTA_OBJ_SHEDULE_DATA_REQUEST){
        return {
            ...state,
            loading:true
        }
    }else if(action.type==FETCH_QTA_OBJ_SHEDULE_DATA_SUCCESS){
        return{
            ...state,
            loading:false,
            quotaSchduleData : action.payload
        }
    }else if(action.type==FETCH_QTA_OBJ_SHEDULE_DATA_FAILURE){
        return{
            ...state,
            loading: false,
            error : action.payload
        }
    }else return state

}



export default QuoataScheduleReducers;