import {
  APPWATCH_PROFILE,
  APPWATCH_PROFILE_ITEM,
  SELECT_LINKUTIL_PROF,
  SELECT_LINKUTIL_PROF_ITEM,
  UPLINK_SERIES,
  DOWNLINK_SERIES,
  SELECT_LINKUTIL_APP_CLR,
  PRV_APPWATCH_PROFILE_ITEM,
  UPLINK_DOWNLINK_SERIES,
  APP_SERVICE_SIG_LIST,
  LOADING_SUBNET
} from '../constants/linkUtil';


const initialState = {
  profile: [],
  items: [],
  selected_prof: null,
  selected_prof_items: [],
  selected_app_clr: [],
  uplink_series: [],
  downlink_series: [],
  prv_items: [],
  app_service_signature_list:[],
  subnetLoading : false
};

const linkutil = (state = initialState, action) => {
  switch (action.type) {
    case APPWATCH_PROFILE:
      return {
        ...state,
        profile: action.profile
      };
    case APPWATCH_PROFILE_ITEM:
      return {
        ...state,
        items: action.items
      };
    case SELECT_LINKUTIL_PROF:
      return {
        ...state,
        selected_prof: action.selected_prof
      };
    case SELECT_LINKUTIL_PROF_ITEM:
      return {
        ...state,
        selected_prof_items: action.selected_prof_items
      };
    case SELECT_LINKUTIL_APP_CLR:
      return {
        ...state,
        selected_app_clr: action.selected_app_clr
      };
    case UPLINK_SERIES:
      return {
        ...state,
        uplink_series: action.uplink_series
      };
    case DOWNLINK_SERIES:
      return {
        ...state,
        downlink_series: action.downlink_series
      };
    case PRV_APPWATCH_PROFILE_ITEM:
      return {
        ...state,
        prv_items: action.prv_items
      }
    case UPLINK_DOWNLINK_SERIES:
      return {
        ...state,
        downlink_series: action.downlink_series,
        uplink_series: action.uplink_series
      }
    case APP_SERVICE_SIG_LIST:
      return {
        ...state,
        app_service_signature_list: action.app_service_signature_list,
        
      }
    case LOADING_SUBNET:
      return {
        ...state,
        subnetLoading: action.isLoading,
        
      }
    default:
      return state;
  }
};

export default linkutil;
