export const REPORT_SOURCE = 'REPORT_SOURCE';
export const REPORT_DEST = 'REPORT_DEST';
export const REPORT_APP = 'REPORT_APP';
export const REPORT_PORT = 'REPORT_PORT';
export const REPORT_SUMMARY = 'REPORT_SUMMARY';
export const REPORT_SESSION = 'REPORT_SESSION';
export const REPORT_FILTER = 'REPORT_FILTER';
export const REPORT_HEADER = 'REPORT_HEADER';
export const REPORT_STANDALONE = 'REPORT_STANDALONE';
export const REPORT_COMPARISON = 'REPORT_COMPARISON';

export const REPORT_BANDWIDTH = 'REPORT_BANDWIDTH';
export const REPORT_BANDWIDTH_INIT = 'REPORT_BANDWIDTH_INIT';
export const REPORT_COMPARISON_NO_DATA = 'REPORT_COMPARISON_NO_DATA';
export const REPORT_BANDWIDTH_COMP_UPDATE = 'REPORT_BANDWIDTH_COMP_UPDATE';
export const REPORT_BANDWIDTH_COMP_NULL_FILTERS = 'REPORT_BANDWIDTH_COMP_NULL_FILTERS';
export const WEEKLY_DAYS_ARRAY = 'WEEKLY_DAYS_ARRAY';

export const FETCH_EMAIL_REPORT_PROFILES = 'FETCH_EMAIL_REPORT_PROFILES'
export const FETCH_EMAIL_REPORT_EMAIL = 'FETCH_EMAIL_REPORT_EMAIL'
export const FETCH_EMAIL_FILTERS = "FETCH_EMAIL_FILTERS"
export const DTR_HEADER = 'DTR_HEADER';

export const DTR_LOADING = 'DTR_LOADING';
export const USER = 'USER';
export const GROUP ='GROUP'

export const DTR_TYPE = 'DTR_TYPE';