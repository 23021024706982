
export const FETCH_QUOTA_DATA_REQUEST = "FETCH_QUOTA_DATA_REQUEST" // Quota Rule Constatnt
export const FETCH_QUOTA_DATA_SUCCESS = "FETCH_QUOTA_DATA_SUCCESS"// Quota Rule Constatnt
export const FETCH_QUOTA_DATA_FAILURE = "FETCH_QUOTA_DATA_FAILURE"// Quota Rule Constatnt

export const REMOVE_ONE_QUOTA_DATA = "REMOVE_ONE_QUOTA_DATA"


export const FETCH_QTA_OBJ_SHEDULE_DATA_REQUEST = "FETCH_QTA_SHEDULE_REQUEST"
export const FETCH_QTA_OBJ_SHEDULE_DATA_SUCCESS = "FETCH_QTA_SHEDULE_SUCCESS"
export const FETCH_QTA_OBJ_SHEDULE_DATA_FAILURE = "FETCH_QTA_SHEDULE_FAILURE"

export const FETCH_QTA_APPLICATION_PROF_LIST = "FETCH_QTA_APPLICATION_PROF_LIST"

export const FETCH_APP_LIST = "FETCH_APP_LIST"

export const FETCH_QUOTA_APPLIST = "FETCH_QUOTA_APPLIST"

export const FETCH_SERVICE_LIST = "FETCH_SERVICE_LIST"
export const FETCH_QUOTA_SERVICE_PROFILE_LIST = "FETCH_QUOTA_SERVICE_PROFILE_LIST"
export const FETCH_QUOTA_SERVICES_LIST = "FETCH_QUOTA_SERVICES_LIST"

export const FETCH_QUOTA_URL_LIST = "FETCH_QUOTA_URL_LIST"
export const FETCH_QUOTA_URL_PROFILE_LIST = "FETCH_QUOTA_URL_PROFILE_LIST"
export const FETCH_QUOTA_URLS = "FETCH_QUOTA_URLS"


export const FETCH_QUOTA_MAC_DATA = "FETCH_QUOTA_MAC_DATA"

export const FETCH_QUOTA_IP_DATA = "FETCH_QUOTA_IP_DATA"

export const FETCH_QUOTA_REMOTE_USERS = 'FETCH_QUOTA_REMOTE_USERS'

export const FETCH_QUOTA_REMOTE_GROUPS = 'FETCH_QUOTA_REMOTE_GROUPS'

export const FETCH_ADDRESSPROFILE = 'ADDRESSPROFILE';
export const FETCH_ADDRESSPROFILE_ADDRESS = 'ADDRESSPROFILE_ADDRESS';

export const FETCH_APP_PROFILE_USAGE_DATA = "FETCH_APPPROFILE_USAGE_DATA"
export const FETCH_QUOTA_PROFILE_USAGE_DATA = "FETCH_QUOTA_PROFILE_USAGE_DATA"

export const QUOTA_UPDATE = 'QUOTA_UPDATE';
export const QUOTA_USAGE = 'QUOTA_USAGE'
export const USAGE ="USAGE"