import * as types from '../constants/reportFilterTypes'

const initialstate = {
  tableData:[]
}

const reportFilter = ( state= initialstate, action) => {
  // console.log("report reducer payload", action.payload);
  switch(action.type){
    case types.GETFILTERDATA:
      return  {
        ...state,
        tableData : action.payload
      }
    default:
      return state;
  }
}
export default reportFilter;