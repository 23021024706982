import axios from 'axios';
import { BASE_URL } from '../constants/baseURL';
import * as types from '../constants/logActionTypes';

export const getRuleaddition = () => {
    return dispatch => {
        axios
            .post(BASE_URL + 'api/rules/objects/rulefind',{
                'query': '',
            }, { withCredentials: true })
            .then(res => {
                const ruledata = res.data
                dispatch(fillRuleaddition(ruledata));
            })
            .catch(err => {
                dispatch(set_snack_bar(true, 2))
            });
    }
}

export const addTimeLog = (username, action) => {
    return dispatch => {
        axios
            .post(BASE_URL + 'api/activity', {
                data: {
                    "user_name": username,
                    "action": action,
                }
            },{ withCredentials: true})
            .then(res => {
               
            })
            .catch(err => { 
                console.log(err);
            });
    };
}


export const addLogWithoutDispatch = (username, action) => {
       
        axios
            .post(BASE_URL + 'api/log/activity', {
                 data: {
                    "user_name": username,
                    "action": action,
                    "timestamp": (new Date().getTime() / 1000).toString()
                }
            },{withCredentials: true})
            .then(res => {
                
            })
            .catch(err => {
             
                console.log(err);
            });
}

export const addLog = (username, action) => {
    return dispatch => {
        
        axios
            .post(BASE_URL + 'api/log/activity', {
                 data: {
                    "user_name": username,
                    "action": action,
                    "timestamp": (new Date().getTime() / 1000).toString()
                }
            },{withCredentials: true})
            .then(res => {
               
            })
            .catch(err => {
              
                console.log(err);
            });
    };
}


export const getLogs = () => {
    return dispatch => {
        axios
            .get(BASE_URL + 'api/log/activity', {
                withCredentials: true
            })
            .then(res => {
               
                dispatch(get_logs(res.data));
            })
            .catch(err => {
                console.log(err);
            });
    };
}


export function get_logs(logs) {
    return { type: types.LOGS, logs };
}
  