export const SQI_LIVE = 'SQI_LIVE';
export const SQI_HISTORY_GRAPH = 'SQI_HISTORY_GRAPH';
export const SQI_HISTORY_APP = 'SQI_HISTORY_APP';
export const SQI_HISTORY_IP = 'SQI_HISTORY_IP';
export const SQI_TCP_RATE = 'SQI_TCP_RATE';
export const SQI_TCP_RATE_PLOT = 'SQI_TCP_RATE_PLOT';
export const LATENCY = 'LATENCY';
export const LATENCY_PLOT = 'LATENCY_PLOT';
export const DELAY_PLOT = 'DELAY_PLOT';
export const FILTER = 'FILTER';
export const HEADER = 'HEADER';
export const COUNT = 'COUNT'
export const AVERAGE = 'AVERAGE'

export const LOADING1 = 'LOADING1';
export const LOADING2 = 'LOADING2';
export const LOADING3 = 'LOADING3';
export const LOADING4 = 'LOADING4';
export const LOADING5 = 'LOADING5';
export const LOADING6 = 'LOADING6';
