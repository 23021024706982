import * as types from '../constants/reportConfigTypes'

const initialstate = {
  receivedData:[]
}

const reportConfig = (state = initialstate, action) => {

  // console.log("reducer state",state);
  
  switch(action.type){
    case types.GETCONFIGDATA:
      // console.log("config reducer payload",action);
      return  {
        ...state,
        receivedData : action.payload
      }
    default:
      return state;
  }
}

export default reportConfig;