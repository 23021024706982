import axios from "axios";
import * as types from "../constants/loginData";
import { BASE_URL } from "../constants/baseURL";
import $ from "jquery";
import { select_user, loginperm } from "./userActions";
import { get_app_sigProf_sigCat_service_list } from "./appservActions";
import { set_snack_bar } from "./userActions";
import { addLogWithoutDispatch } from "actions/activityActions";
import { history } from "store/configureStore";
import { set_users_typeslogin } from "./userActions";

export const login = ({ username, password }) => {
  return (dispatch) => {
    axios
      .post(
        BASE_URL + "api/login",
        $.param({
          username: username,
          password: password,
        }),
        { withCredentials: true }
      )
      .then((res) => {
        dispatch(loginperm());
        dispatch(select_user(""));
        dispatch(authAction(true));
        dispatch(loginnameAction(res.data.username));
        dispatch(loginidAction(res.data.id, res.data.type));
        localStorage.setItem(
          "reportQuickCustomMode",
          JSON.stringify(["quick"])
        );
        localStorage.setItem("reportQuickMode", JSON.stringify(["1"]));
        localStorage.setItem(
          "usageReport",
          JSON.stringify([
            { quickVal: "0", selectedValue: "quick", app: 65536, link: 1 },
          ])
        );
        localStorage.setItem(
          "sessionwatchSessionsHeading",
          JSON.stringify([
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
          ])
        );
        localStorage.setItem(
          "sessionwatchSourceHeading",
          JSON.stringify([true, true, true, true, true, true])
        );
        localStorage.setItem(
          "sessionwatchDestinationHeading",
          JSON.stringify([true, true, true, true, true, true])
        );
        localStorage.setItem(
          "sessionwatchApplicationHeading",
          JSON.stringify([true, true, true, true, true])
        );
        localStorage.setItem(
          "sessionwatchServiceHeading",
          JSON.stringify([true, true, true, true, true])
        );
        localStorage.setItem(
          "sessionwatchAutoRefresh",
          JSON.stringify([false])
        );
        localStorage.setItem("sortedColumnAppProf", JSON.stringify("none"));
        localStorage.setItem("sortDirectionAppProf", JSON.stringify("asc"));
        localStorage.setItem(
          "sortedColumnAddressProfItem",
          JSON.stringify("none")
        );
        localStorage.setItem(
          "sortDirectionAddressProfItem",
          JSON.stringify("asc")
        );
        localStorage.setItem("sortedColumnAddressProf", JSON.stringify("none"));
        localStorage.setItem("sortDirectionAddressProf", JSON.stringify("asc"));
        localStorage.setItem(
          "sortedColumnRuleschedules",
          JSON.stringify("none")
        );
        localStorage.setItem(
          "sortDirectionRuleschedules",
          JSON.stringify("asc")
        );
        localStorage.setItem(
          "sortedColumnQuotaschedules",
          JSON.stringify("none")
        );
        localStorage.setItem(
          "sortDirectionQuotaschedules",
          JSON.stringify("asc")
        );
        localStorage.setItem("sortedColumnPipes", JSON.stringify("none"));
        localStorage.setItem("sortDirectionPipes", JSON.stringify("asc"));
        localStorage.setItem(
          "sortedColumnQuotaProfile",
          JSON.stringify("none")
        );
        localStorage.setItem(
          "sortDirectionQuotaProfile",
          JSON.stringify("asc")
        );
        localStorage.setItem(
          "sortedColumnCustomSignature",
          JSON.stringify("none")
        );
        localStorage.setItem(
          "sortDirectionCustomSignature",
          JSON.stringify("asc")
        );
        localStorage.setItem(
          "sortedColumnCustomSignatureCategory",
          JSON.stringify("none")
        );
        localStorage.setItem(
          "sortDirectionCustomSignatureCategory",
          JSON.stringify("asc")
        );
        localStorage.setItem(
          "sortedColumnCustomSignatureItem",
          JSON.stringify("none")
        );
        localStorage.setItem(
          "sortDirectionCustomSignatureItem",
          JSON.stringify("asc")
        );
        localStorage.setItem("sorttypeSessionWatchSource", JSON.stringify(0));
        localStorage.setItem("sortOrderSessionWatchSource", JSON.stringify(11));
        localStorage.setItem("sorttypeSessionWatchSessions", JSON.stringify(0));
        localStorage.setItem(
          "sortOrderSessionWatchSessions",
          JSON.stringify(11)
        );
        localStorage.setItem("sorttypeSessionWatchPort", JSON.stringify(0));
        localStorage.setItem("sortOrderSessionWatchPort", JSON.stringify(11));
        localStorage.setItem(
          "sorttypeSessionWatchDestination",
          JSON.stringify(0)
        );
        localStorage.setItem(
          "sortOrderSessionWatchDestination",
          JSON.stringify(11)
        );
        localStorage.setItem(
          "sorttypeSessionWatchApplication",
          JSON.stringify(0)
        );
        localStorage.setItem(
          "sortOrderSessionWatchApplication",
          JSON.stringify(11)
        );
        localStorage.setItem("sorttypeMac", JSON.stringify(1));
        localStorage.setItem("sortOrderMac", JSON.stringify(0));
        localStorage.setItem("sorttypeIp", JSON.stringify(1));
        localStorage.setItem("sortOrderIP", JSON.stringify(0));
        localStorage.setItem("sorttypeRepSou", JSON.stringify(5));
        localStorage.setItem("sortOrderRepSou", JSON.stringify(0));
        localStorage.setItem("sorttypeRepPort", JSON.stringify(5));
        localStorage.setItem("sortOrderRepPort", JSON.stringify(0));
        localStorage.setItem("sorttypeRepDes", JSON.stringify(5));
        localStorage.setItem("sortOrderRepDes", JSON.stringify(0));
        localStorage.setItem("sorttypeRepApp", JSON.stringify(5));
        localStorage.setItem("sortOrderRepApp", JSON.stringify(0));
        localStorage.setItem("sortSqiLive", JSON.stringify(0));
        localStorage.setItem("sortSqiLatency", JSON.stringify(0));
        localStorage.setItem("sortSqiTcp", JSON.stringify(0));
        localStorage.setItem("sorttypeRuleStat", JSON.stringify(0));
        localStorage.setItem("sortOrderRuleStat", JSON.stringify(0));
        localStorage.setItem("QuickorCustom", JSON.stringify(["quick"]));
        // dispatch(get_users());

        if (parseInt(res.data.type) === 23) {
          dispatch(select_user(0));
        }
        addLogWithoutDispatch(
          username,
          "Logged in successfully '" + username + "'"
        );
      })
      .catch((err) => {
        let remaining_point;
        let state;
        if (err.response !== undefined) {
          remaining_point = parseInt(err.response.data.remaining_point);
          state = err.response.data.state;
          if (state === true) {
            dispatch(set_snack_bar(true, 41));
          } else {
            if (err.response.data.remaining_point < 4) {
              dispatch(set_snack_bar(true, 25 + remaining_point));
            }
          }
        } else {
          dispatch(set_snack_bar(true, 2));
        }
        addLogWithoutDispatch(
          username,
          "Authentication failure at Login by '" + username + "'"
        );
        dispatch(authAction(false));
      });
  };
};

export const Auth = () => {
  return (dispatch) => {
    axios
      .get(BASE_URL + "api/Auth", { withCredentials: true })
      .then((res) => {
        dispatch(authAction(true));
        dispatch(loginnameAction(res.data.username));
        dispatch(loginidAction(res.data.id, res.data.type));
        // dispatch(get_users());
        dispatch(loginperm());
        dispatch;
        if (parseInt(res.data.type) === 23) {
          dispatch(select_user(0));
        }
        setTimeout(() => {
          dispatch(get_app_sigProf_sigCat_service_list());
          // dispatch(get_services());
        }, 50);
      })
      .catch((err) => {
        localStorage.clear();
        dispatch(set_users_typeslogin([]));
        dispatch(authAction(false, 0));
        dispatch(loginnameAction(""));
        dispatch(loginidAction("", 0));
      });
  };
};

export const logout = () => {
  return (dispatch) => {
    axios
      .get(BASE_URL + "api/logout", { withCredentials: true })
      .then((res) => {
        localStorage.clear();
        dispatch(set_users_typeslogin([]));
        dispatch(authAction(false, 0));
        dispatch(loginnameAction(""));
        dispatch(loginidAction("", 0));
        history.push("/user/login");
      })
      .catch((err) => {
        dispatch(authAction(false, 0));
        dispatch(loginnameAction(""));
        dispatch(loginidAction("", 0));
        dispatch(set_users_typeslogin([]));
      });
  };
};

export function authAction(authentication) {
  return { type: types.AUTHENTICATION, authentication };
}
export function loginnameAction(loginName) {
  return { type: types.LOGINUSERNAME, loginName };
}
export function loginidAction(loginId, loginType) {
  return { type: types.LOGINUSERID, loginId, loginType };
}
