import {
    BYPASS_STATUS,
  } from '../constants/maintenance';

  
const initialState = {
    status : 0
};

const maintenance = (state = initialState, action) => {
    switch (action.type) {
        case BYPASS_STATUS:
          return {
            ...state,
            status: action.status
          };
        default:
          return state;
    }
};

export default maintenance;