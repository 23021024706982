import  { 
     SUMMARY_NAV_STARTDATE,
     SUMMARY_NAV_SELECTEDDATE, 
     SUMMARY_NAV_SELECTEDDURATION
} from "../constants/actionTypes";

const initialstate = {
            
            startDate:'',
            selectedDate1: new Date(),
            
};

const summary_navbar = (state = initialstate, action) => {
           switch (action.type) {
                case SUMMARY_NAV_STARTDATE:
                   return{
                       ...state,
                startDate:action.startDate
                   };
                case SUMMARY_NAV_SELECTEDDATE:
                    return{
                        ...state,
                        selectedDate1:action.selectedDate1                       
                    }
                case SUMMARY_NAV_SELECTEDDURATION:
                    return{
                        ...state,
                        selectedDuration:action.selectedDuration
                    }
                default:
                    return state;
           }
}
export default summary_navbar;
 