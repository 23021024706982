import {
    LDAP,
    AD_USERS,
    AD_GROUPS,
    EXPORTS,
    ENABLED
} from '../constants/ldap';


const initialState = {
    ldap: [],
    ad_users: [],
    ad_groups: [],
    exports:[],
    enabled:[],
};

const ldap = (state = initialState, action) => {
    switch (action.type) {
        case LDAP:
            return {
                ...state,
                ldap: action.ldap
            };
        case AD_USERS:
            return {
                ...state,
                ad_users: action.ad_users
            };
        case AD_GROUPS:
            return {
                ...state,
                ad_groups: action.ad_groups
            };

        case EXPORTS:
            return { 
                ...state,
                exports: action.exports
            };
        case ENABLED:
                return {
                    ...state,
                    enabled: action.enabled
                };

        default:
            return state;
    }
};

export default ldap;