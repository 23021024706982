export const DEVICE_LIST = "DEVICE_LIST";
export const CONNECTION_LIST = "CONNECTION_LIST";
export const LOADING = "LOADING";
export const DEVICE_TYPE_LIST = "DEVICE_TYPE_LIST";
export const INTERFACES_LIST = "INTERFACES_LIST";
export const REGION_LIST = "REGION_LIST"
export const REGION_CONNECTION_LIST = "REGION_CONNECTION_LIST"
export const DEVICE_MONITOR_LIST = "DEVICE_MONITOR_LIST"
export const DEVICE_STATUS_LIST = "DEVICE_STATUS_LIST"

