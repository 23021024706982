import {
  SIGNATURE_PROFILE,
  SIGNATURE_PROFILE_ITEM,
  SELECTED_PROFILE_ID,
  SIGNATURE_CATEGORY,
  SIGNATURE_CATEGORY_APP_LIST,
  SIGNATURE_CATEGORY_FREE_APP_LIST,
  SIGNATURE_CATEGORY_APP,
  SIGNATURE_PROFILE_ALL_ITEM,
  NEW_SIGNATURE_CATEGORY,
  NEW_SIGNATURE_PROFILE_ITEM,
} from "../constants/customSignature";

const initialState = {
  signatureProfile: [],
  signatureProfileItem: [],
  signatureProfileAllItem: [],
  selectedProfileId: [],
  signatureCategory: [],
  signatureCategoryAppList: [],
  signatureCategoryFreeAppList: [],
  signatureCategoryApp: [],
  newSigatureCategory: "",
};

const customSignature = (state = initialState, action) => {
  switch (action.type) {
    case SIGNATURE_PROFILE:
      return {
        ...state,
        signatureProfile: action.signatureProfile,
      };
    case SIGNATURE_PROFILE_ITEM:
      return {
        ...state,
        signatureProfileItem: action.signatureProfileItem,
      };

    case NEW_SIGNATURE_PROFILE_ITEM:
      return {
        ...state,
        newSignatureProfileItem: action.newSignatureProfileItem,
      };

    case SIGNATURE_PROFILE_ALL_ITEM:
      return {
        ...state,
        signatureProfileAllItem: action.signatureProfileAllItem,
      };

    case SELECTED_PROFILE_ID:
      return {
        ...state,
        selectedProfileId: action.selectedProfileId,
      };

    case SIGNATURE_CATEGORY:
      return {
        ...state,
        signatureCategory: action.signatureCategory,
      };
    case NEW_SIGNATURE_CATEGORY:
      return {
        ...state,
        newSigatureCategory: action.newSigatureCategory,
      };
    case SIGNATURE_CATEGORY_APP_LIST:
      return {
        ...state,
        signatureCategoryAppList: action.signatureCategoryAppList,
      };
    case SIGNATURE_CATEGORY_FREE_APP_LIST:
      return {
        ...state,
        signatureCategoryFreeAppList: action.signatureCategoryFreeAppList,
      };
    case SIGNATURE_CATEGORY_APP:
      return {
        ...state,
        signatureCategoryApp: action.signatureCategoryApp,
      };
    default:
      return state;
  }
};

export default customSignature;
