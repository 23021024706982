export const SOURCES_DOWNLINK = 'SOURCES_DOWNLINK';
export const SOURCES_UPLINK = 'SOURCES_UPLINK';
export const DASHBOARD_LIVE_GRAPH = 'DASHBOARD_LIVE_GRAPH';
export const DASHBOARD_LIVE_MS_GRAPH = 'DASHBOARD_LIVE_MS_GRAPH';
export const DASHBOARD_LIVE_GRAPH_DES = 'DASHBOARD_LIVE_GRAPH_DES';
export const DASHBOARD_LIVE_MS_GRAPH_DES = 'DASHBOARD_LIVE_MS_GRAPH_DES';
export const DASHBOARD_LIVE_GRAPH_SRC = 'DASHBOARD_LIVE_GRAPH_SRC';
export const DASHBOARD_LIVE_MS_GRAPH_SRC = 'DASHBOARD_LIVE_MS_GRAPH_SRC';
export const DESTINATIONS_DOWNLINK = 'DESTINATIONS_DOWNLINK';
export const DESTINATIONS_UPLINK = 'DESTINATIONS_UPLINK';
export const APPLICATIONS_DOWNLINK = 'APPLICATIONS_DOWNLINK';
export const APPLICATIONS_UPLINK = 'APPLICATIONS_UPLINK';
export const TOP_SOURCES = 'TOP_SOURCES';
export const TOP_DESTINATIONS = 'TOP_DESTINATIONS';
export const TOP_APPLICATIONS = 'TOP_APPLICATIONS';
export const TOP_PORTS = 'TOP_PORTS';
export const IS_PIE_CHART = 'IS_PIE_CHART';
export const EVENTS = 'EVENTS';
export const UPTIME = 'UPTIME';
export const VLAN_LIST = 'VLAN_LIST';
export const LIVE_WATCH_STATE = 'LIVE_WATCH_STATE'
export const DASHBOARD_INITIAL_LOADING = "DASHBOARD_INITIAL_LOADING"
export const DASHBOARD_INITIAL_LOADING_MS = "DASHBOARD_INITIAL_LOADING_MS"
export const LIVE_PLOT_PAUSE = 'LIVE_PLOT_PAUSE';
export const DASHBOARDLOADING = 'DASHBOARDLOADING';
