import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import { set_snack_bar } from 'actions/userActions';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({


});
class Notification1 extends React.Component {

    handleonclose = () => {
        this.props.onClose(false, -1);
    };

    render() {
        const { classes, type, open } = this.props;
        let message, color = null;
        switch (type) {
            case 2:
                message = "Operation failed!";
                color = '#d32f2f';
                break;
            case 4:
                message = "New Entry already exists in the System!";
                color = '#ffa000';
                break;
            case 6:
                message = "Invalid File!";
                color = '#ffa000';
                break;
            case 7:
                message = "Outdated Installation File!";
                color = '#ffa000';
                break;
            case 10:
                message = "Profile updated successfully";
                color = '#43a047';
                break;
            case 11:
                message = "You have reached the Maximum Entry Limit!";
                color = '#ffa000';
                break;
            case 12:
                message = "New Entry is Invalid!";
                color = '#ffa000';
                break;
            case 14:
                message = "Entry already exists in database!";
                color = '#ffa000';
                break;
            case 16:
                message = "Cannot delete! Profile is already in use.";
                color = '#d32f2f';
                break;
            case 17:
                message = "Invalid Shaper configuration!";
                color = '#ffa000';
                break;
            case 18:
                message = "Invalid Address entry! Collision at source and destination entries!";
                color = '#ffa000';
                break;
            case 19:
                message = "Invalid Address entry to Profile!";
                color = '#ffa000';
                break;
            case 20:
                message = "User limit exceeded!";
                color = '#ffa000';
                break;
            case 21:
                message = "You cannot delete the default user/server";
                color = '#ffa000';
                break;
            case 22:
                message = "Updated Succesfully";
                color = "#43a047";
                break;
            case 23:
                message = "Created Succesfully";
                color = "#43a047";
                break;
            case 24:
                message = "Deleted Succesfully"
                color = "#43a047";
                break;
            case 25:
                message = "Please Wait 10 Minutes Before You Try Again"
                color = '#d32f2f';
                break;
            case 28:
                message = "Incorrect login credentials. You have three attempts left"
                color = '#d32f2f';
                break;
            case 27:
                message = "Incorrect login credentials. You have two attempts left"
                color = '#d32f2f';
                break;
            case 26:
                message = "Incorrect login credentials. You have one attempts left"
                color = '#d32f2f';
                break;
            case 29:
                message = "Reload Finished"
                color = '#43a047';
                break;
            case 30:
                message = "Invalid Filter Input"
                color = '#d32f2f';
                break;
            case 31:
                message = "Custom Signature Added Successfully";
                color = "#43a047";
                break;
            case 32:
                message = "Custom Signature Updated Successfully";
                color = "#43a047";
                break;
            case 33:
                message = "Deleted Succesfully"
                color = "#43a047";
                break;
            case 34:
                message = "Signature Item Added Successfully";
                color = "#43a047";
                break;
            case 35:
                message = "Signature Item Updated Successfully";
                color = "#43a047";
                break;
            case 36:
                message = "Signature Category Added Successfully";
                color = "#43a047";
                break;
            case 37:
                message = "Signature Category Updated Successfully";
                color = "#43a047";
                break;
            case 38:
                message = "Updated Successfully";
                color = "#43a047";
                break;
            case 39:
                message = "Added Successfully";
                color = "#43a047";
                break;
            case 40:           
                message = "SNMP is not enabled in Device";
                color = '#d32f2f';
                break;
            case 41:
                message = "Unauthenticated user";
                color = "#d32f2f";
                break;
            case 42:
                message = "File Uploaded Successfully";
                color = "#43a047";
                break;
            default:
                message = null;
                color = null;
        }
        return (
            <SnackbarStatus open={open} color={color} message={message} onclose={this.handleonclose} classes={classes} />
        );
    }
}

const SnackbarStatus = (props) => {

    const { classes } = props;

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
            }}
            open={props.open}
            onClose={props.onclose}
            autoHideDuration={2000}>
            <SnackbarContent
                // onClose={this.handleClose}
                style={{ backgroundColor: props.color }}
                message={<span id="toast">{props.message}</span>}
                action={[
                    <IconButton key="close" aria-label="Close" color="inherit" onClick={props.onclose}>
                        <CloseIcon className={classes.icon} />
                    </IconButton>
                ]}
            />
        </Snackbar>
    );
};


// Notification.propTypes = {
//     type: PropTypes.string,
// };

// Notification.defaultProps = {
//     type: '',
// };
Notification1.propTypes = {
    classes: PropTypes.object.isRequired,
};

const Notification = withStyles(styles)(Notification1);

const mapStateToProps = state => ({
    open: state.users.snackbaropen,
    type: state.users.snackbartype
});

const mapDispatchToProps = dispatch => ({
    onClose: (isopen, val) => {
        dispatch(set_snack_bar(isopen, val));
    }

});



export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Notification);