import {
  SESSION_DATA,
  SESSION_DATA_LOADING,
  SOURCES_DATA,
  SOURCES_DATA_LOADING,
  DESTINATIONS_DATA,
  DESTINATIONS_DATA_LOADING,
  APPLICATIONS_DATA,
  APPLICATIONS_DATA_LOADING,
  SERVICES_DATA,
  SERVICES_DATA_LOADING,
  RESPONSE_NULL,
  SOURCES_PIE,
  DESTINATIONS_PIE,
  APPLICATIONS_PIE,
  SERVICES_PIE,
  SOURCE_DIAGRAM,
  DESTINATION_DIAGRAM,
  APPLICATION_DIAGRAM,
  SERVICE_DIAGRAM,
  RULE_ID,
} from '../constants/sessionData';

const initialData = {
  ses_ses_data: [],
  ses_ses_data_loading: false,
  ses_sou_data: [],
  ses_sou_data_loading: false,
  ses_des_data: [],
  ses_des_data_loading: false,
  ses_app_data: [],
  ses_app_data_loading: false,
  ses_sev_data: [],
  ses_sev_data_loading: false,
  null_data: false,
  ses_sou_pie: [],
  ses_des_pie: [],
  ses_app_pie: [],
  ses_ser_pie: [],
  ses_sou_dia: [],
  ses_des_dia: [],
  ses_app_dia: [],
  ses_ser_dia: [],
  rule_id: 0,
  ses_ses_count:0,
  ses_sou_count:0,
  ses_des_count:0,
  ses_app_count:0,
  ses_sev_count:0,
};

const session = (state = initialData, action) => {
  switch (action.type) {
    case SESSION_DATA:
      return {
        ...state,
        ses_ses_data: action.ses_ses_data.rows,
        ses_ses_count: action.ses_ses_data.count,
      };
    case SESSION_DATA_LOADING:
      return {
        ...state,
        ses_ses_data_loading: action.value,
      };
    case SOURCES_DATA:
      return {
        ...state,
        ses_sou_data: action.ses_sou_data.rows,
        ses_sou_count: action.ses_sou_data.count
      };
    case SOURCES_DATA_LOADING:
      return {
        ...state,
        ses_sou_data_loading: action.value
      };
    case DESTINATIONS_DATA:
      return {
        ...state,
        ses_des_data: action.ses_des_data.rows,
        ses_des_count: action.ses_des_data.count
        
      };
    case DESTINATIONS_DATA_LOADING:
      return {
        ...state,
        ses_des_data_loading: action.value
      };
    case APPLICATIONS_DATA:
      return {
        ...state,
        ses_app_data: action.ses_app_data.rows,
        ses_app_count: action.ses_app_data.count
      };
    case APPLICATIONS_DATA_LOADING:
      return {
        ...state,
        ses_app_data_loading: action.value
      };
    case SERVICES_DATA:
      return {
        ...state,
        ses_sev_data: action.ses_sev_data.rows,
        ses_sev_count: action.ses_sev_data.count
      };
    case SERVICES_DATA_LOADING:
      return {
        ...state,
        ses_sev_data_loading: action.value
      };
    case RESPONSE_NULL:
      return {
        ...state,
        null_data: action.null_data
      };
    case SOURCES_PIE:
      return {
        ...state,
        ses_sou_pie: action.ses_sou_pie
      };
    case DESTINATIONS_PIE:
      return {
        ...state,
        ses_des_pie: action.ses_des_pie
      };
    case APPLICATIONS_PIE:
      return {
        ...state,
        ses_app_pie: action.ses_app_pie
      };
    case SERVICES_PIE:
      return {
        ...state,
        ses_ser_pie: action.ses_ser_pie
      };
    case SOURCE_DIAGRAM:
      return {
        ...state,
        ses_sou_dia: action.ses_sou_dia
      };
    case DESTINATION_DIAGRAM:
      return {
        ...state,
        ses_des_dia: action.ses_des_dia
      };
    case APPLICATION_DIAGRAM:
      return {
        ...state,
        ses_app_dia: action.ses_app_dia
      };
    case SERVICE_DIAGRAM:
      return {
        ...state,
        ses_ser_dia: action.ses_ser_dia
      };
    case RULE_ID:
      return {
        ...state,
        rule_id: action.rule_id
      };
    default:
      return state;
  }
};

export default session;
