import {
  HIGHAVAILABLITY,
  RESET
} from '../constants/settingsType';


const initialState = {
  device : [],
  reset: false
};

const ha = (state = initialState, action) => {
  switch (action.type) {
      case HIGHAVAILABLITY:
        return {
          ...state,
          device: action.device
        };
      case RESET:
        return {
          ...state,
          reset: action.reset
        };
      default:
        return state;
  }
};

export default ha;
