import{IPV4_APP_USAGE,
    IPV6_APP_USAGE,
    IPV4_V6_SESSION_COUNT} from '../constants/ipstatUsage'


const initialState = {
    ipv4Usage : null,
    ipv6Usage : null,
    ipv4v6_ses_count : null,
    ipv6session : 0
}

const ipStatReducer =(state=initialState , action)=>{
    if(action.type === IPV4_APP_USAGE){
        return{
            ...state,
            ipv4Usage : action.ipv4_app_usage
        }
    }else if(action.type === IPV6_APP_USAGE){
        return{
            ...state,
            ipv6Usage : action.ipv6_app_usage
        }
    }else if(action.type ===IPV4_V6_SESSION_COUNT ){
        return{
            ...state,
            ipv4v6_ses_count : action.ipv4v6_ses_count
        }
    }else return state
}

export default ipStatReducer