
import {
    TRAFFIC_DIAGRAM
} from '../constants/traffic_diagram';

const initial = {
    downlink : {},
    uplink   : {}
};

const login = (state = initial, action) => {
    // console.log(action,state)
    switch (action.type) {
        case TRAFFIC_DIAGRAM:
          return {
            ...state,
            downlink: action.downlink,
            uplink: action.uplink,
          };
        default:
          return state;
    }
};

export default login;

