import {
    MANAGEMENT
  } from '../constants/settingsType';

  
const initialState = {
    mgnt_interface : []
};

const management = (state = initialState, action) => {
    switch (action.type) {
        case MANAGEMENT:
          return {
            ...state,
            mgnt_interface: action.data
          };
        default:
          return state;
    }
};

export default management;