export const SIGNATURE_PROFILE = "SIGNATURE_PROFILE";
export const SIGNATURE_PROFILE_ITEM = "SIGNATURE_PROFILE_ITEM";
export const NEW_SIGNATURE_PROFILE_ITEM = "NEW_SIGNATURE_PROFILE_ITEM";
export const SIGNATURE_PROFILE_ALL_ITEM = "SIGNATURE_PROFILE_ALL_ITEM";
export const SELECTED_PROFILE_ID = "SELECTED_PROFILE_ID";
export const SIGNATURE_CATEGORY = "SIGNATURE_CATEGORY";
export const NEW_SIGNATURE_CATEGORY = "NEW_SIGNATURE_CATEGORY";
export const SIGNATURE_CATEGORY_APP_LIST = "SIGNATURE_CATEGORY_APP_LIST";
export const SIGNATURE_CATEGORY_FREE_APP_LIST =
  "SIGNATURE_CATEGORY_FREE_APP_LIST";
export const SIGNATURE_CATEGORY_APP = "SIGNATURE_CATEGORY_APP";
