import {FETCH_QUOTA_DATA_REQUEST,
        FETCH_QUOTA_DATA_SUCCESS,
        FETCH_QUOTA_DATA_FAILURE,
        FETCH_APP_LIST,
        FETCH_QTA_APPLICATION_PROF_LIST,
        FETCH_QUOTA_APPLIST,
        FETCH_SERVICE_LIST,
        FETCH_QUOTA_SERVICE_PROFILE_LIST,
        FETCH_QUOTA_SERVICES_LIST,
        FETCH_QUOTA_URL_LIST,
        FETCH_QUOTA_URL_PROFILE_LIST,
        FETCH_QUOTA_URLS,
        FETCH_QUOTA_MAC_DATA,
        FETCH_QUOTA_IP_DATA,
        FETCH_QUOTA_REMOTE_USERS,
        FETCH_QUOTA_REMOTE_GROUPS,
        FETCH_ADDRESSPROFILE,
        FETCH_ADDRESSPROFILE_ADDRESS,
        FETCH_APP_PROFILE_USAGE_DATA,
        FETCH_QUOTA_PROFILE_USAGE_DATA,
        QUOTA_UPDATE,
        QUOTA_USAGE,
        USAGE
        } from '../constants/quota'

const initialState = {
    loading : false,
    quotaData : [],
    count:0,
    applist : [],
    error : "",
    quotaAppProfiles:[],
    quotaAppList : [],
    serviceList : [],
    quotaServiceProfiles : [],
    quotaServices : [],
    urlList : [],
    urlProfiles : [],
    quotaUrls : [],
    macData : [],
    ipData : [],
    remoteUserData : [],
    remoteGroupsData : [],
    addressProfile : [],
    addressprofAddress : [],
    appProfileUsage : [],
    quotaProfileUsage : [],
    quotaupdate:[],
    quotausage:[],
    usage:[]
}


 const  quotaReducers = (state = initialState , action) =>{

        if(action.type === FETCH_QUOTA_DATA_REQUEST){
            return { 
                    ...state,
                    loading: true
                }
        }else if(action.type === FETCH_QUOTA_DATA_SUCCESS){
            return{
                ...state,
                loading:false,
                quotaData: action.payload.rows,
                count:action.payload.count,
                error : ''
            }
        }else if(action.type === FETCH_QUOTA_DATA_FAILURE){
            return{
                ...state,
                loading:false,
                error : action.payload,
                quotaData: []
            }
        }else if(action.type === FETCH_APP_LIST){
            return{
                ...state,
                applist : action.payload
            }
        }else if(action.type === FETCH_QTA_APPLICATION_PROF_LIST){
            return{
                ...state,
                quotaAppProfiles : action.payload
            }
        }else if(action.type ===FETCH_QUOTA_APPLIST ){
            return{
                ...state,
                quotaAppList : action.payload
            }
        }else if(action.type === FETCH_SERVICE_LIST){
            return{
                ...state,
                serviceList : action.payload
            }
        }else if(action.type === FETCH_QUOTA_SERVICE_PROFILE_LIST){
            return{
                ...state,
                quotaServiceProfiles : action.payload
            }
        }else if(action.type === FETCH_QUOTA_SERVICES_LIST){
            return{
                ...state,
                quotaServices : action.payload
            }
        }else if(action.type === FETCH_QUOTA_URL_LIST ){
            return{
                ...state,
                urlList : action.payload
            }
        }else if(action.type === FETCH_QUOTA_URL_PROFILE_LIST){
            return{
                ...state,
                urlProfiles : action.payload
            }
        }else if(action.type === FETCH_QUOTA_URLS){
            return{
                ...state,
                quotaUrls : action.payload
            }
        }else if(action.type === FETCH_QUOTA_MAC_DATA){
            return{
                ...state,
                macData : action.payload
            }
        }else if(action.type === FETCH_QUOTA_IP_DATA){
            return{
                ...state,
                ipData : action.payload
            }
        }else if(action.type === FETCH_QUOTA_REMOTE_USERS){
            return{
                ...state,
                remoteUserData : action.payload

            }
        }else if(action.type === FETCH_QUOTA_REMOTE_GROUPS){
            return{
                ...state,
                remoteGroupsData : action.payload
            }
        }else if(action.type === FETCH_ADDRESSPROFILE) {
            return {
                ...state,
                addressProfile : action.payload
            }
        }else if(action.type === FETCH_ADDRESSPROFILE_ADDRESS){
            return{
                ...state,
                addressprofAddress : action.payload
            }
        }else if(action.type === FETCH_APP_PROFILE_USAGE_DATA){
            return{
                ...state,
                appProfileUsage : action.payload
            }
        }else if(action.type === FETCH_QUOTA_PROFILE_USAGE_DATA){
            return{
                ...state,
                quotaProfileUsage : action.payload
            }
        }
        else if(action.type === QUOTA_UPDATE){
            return{
                ...state,
                quotaupdate : action.quotaupdate
            }
        }
        else if(action.type === USAGE){
            return{
                ...state,
                usage : action.usage
            }
        }
        else if(action.type === QUOTA_USAGE){
            return{
                ...state,
                quotausage : action.quotausage
            }
        }else return state
    }

    export default quotaReducers;