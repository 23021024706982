
import {
    USERS_DATA,
    SELECT_USER,
    CUSTOM_USER_TYPE,
    SNACK_BAR,
    SELECT_USER_IPS,
    USER_TYPE,
    USER_TYPE_LOGIN
  } from '../constants/userData';
  
  const initialData = {
    table_data:[],
    selectuserID:'',
    custeomUserType:[],
    snackbaropen:false,
    snackbartype:-1,
    ip_list:[],
    types: [],
    types_login:[]
  };
  
  const users = (state = initialData, action) => {
      // console.log(action,state)
      switch (action.type) {
          case USERS_DATA:
            return {
              ...state,
              table_data: action.table_data
            };
            case SELECT_USER:
              return {
                ...state,
                selectuserID: action.userid
              };
            case CUSTOM_USER_TYPE:
              return {
                ...state,
                custeomUserType: action.customtypes
              };
            case SNACK_BAR:
              return {
                ...state,
                snackbaropen: action.open,
                snackbartype: action.stype
              };
            case SELECT_USER_IPS:
              return {
                ...state,
                ip_list: action.iplist
              };
            case USER_TYPE:
              return {
                ...state,
                types: action.types
              };
              case USER_TYPE_LOGIN:
              return {
                ...state,
                types_login: action.types
              };
          default:
            return state;
      }
  };
  
  export default users;
  