import {
  LIVE_SESSION_DATA,
  LIVE_USER_DOWNLINK_DATA,
  LIVE_USER_UPLINK_DATA,
  LIVE_USER_COUNT,
  

 
  
  URL_WATCH_TIMES,
  URL_WATCH_HTTP,
  URL_WATCH_HTTPS,
  URL_WATCH_DNS,
  URL_WATCH_HTTP_TABLE,
  URL_WATCH_HTTPS_TABLE,
  URL_WATCH_DNS_TABLE,
  URL_WATCH_PAUSE,
  LIVE_LINK_UPLINK_DATA,
  LIVE_LINK_DOWNLINK_DATA,
  LIVE_LINK_TOTAL_SESSION,
  LIVE_LINK_SESSION_PER_SEC,
  DASHBOARD_LIVE,
  DASHBOARD_LIVE_MS,
  DASHBOARD_VLAN,
  DASHBOARD_VLAN_MAX,
  PEAKUSAGE,
  IP_STATISTICS,
  PEAKUSAGEUP,
  PEAKUSAGEDWON,
  PEAKUSAGETOTAL
} from '../constants/live_data';

const initialSettings = {

  session_data: [],
  
  
  user_up: [],
  user_down: [],
  count: 0,
  
 
  times: [],
  http_all: [],
  https_all: [],
  dns_all: [],
  http_table: [],
  https_table: [],
  dns_table: [],
  pause: false,
  dash_grp_up: [],
  dash_grp_down: [],
  dash_grp_ms_up: [],
  dash_grp_ms_down: [],
  dash_vl_grp_up: [],
  dash_vl_grp_down: [],
  dash_vl_max_grp_up: [],
  dash_vl_max_grp_down: [],
  totalsession: 0,
  sessionpersec: 0,
  peakup: [],
  peakdown: [],
  peaktotal:[],
};

const live = (state = initialSettings, action) => {
  // console.log(action,state)
  switch (action.type) {
    
    case LIVE_SESSION_DATA:
      return {
        ...state,
        session_data: action.session_data
      };
    
   
    case LIVE_USER_DOWNLINK_DATA:
      return {
        ...state,
        user_down: action.user_down
      };
    case LIVE_USER_UPLINK_DATA:
      return {
        ...state,
        user_up: action.user_up
      };
    case LIVE_USER_COUNT:
      return {
        ...state,
        count: action.count
      };
  
    case URL_WATCH_TIMES:
      return {
        ...state,
        times: action.times
      };
    case URL_WATCH_HTTP:
      return {
        ...state,
        http_all: action.http_all
      };
    case URL_WATCH_HTTPS:
      return {
        ...state,
        https_all: action.https_all
      };
    case URL_WATCH_DNS:
      return {
        ...state,
        dns_all: action.dns_all
      };
    case URL_WATCH_HTTP_TABLE:
      return {
        ...state,
        http_table: action.http_table
      };
    case URL_WATCH_HTTPS_TABLE:
      return {
        ...state,
        https_table: action.https_table
      };
    case URL_WATCH_DNS_TABLE:
      return {
        ...state,
        dns_table: action.dns_table
      };
    case URL_WATCH_PAUSE:
      return {
        ...state,
        pause: action.pause
      };
    case LIVE_LINK_UPLINK_DATA:
      return {
        ...state,
        dash_grp_up: action.dash_grp_up
      };
    case LIVE_LINK_DOWNLINK_DATA:
      return {
        ...state,
        dash_grp_down: action.dash_grp_down
      };
    case LIVE_LINK_TOTAL_SESSION:
      return {
        ...state,
        totalsession: action.totalsession
      };
    case LIVE_LINK_SESSION_PER_SEC:
      return {
        ...state,
        sessionpersec: action.sessionpersec
      };
    case DASHBOARD_LIVE:
      return {
        ...state,
        dash_grp_down: action.dash_grp_down,
        dash_grp_up: action.dash_grp_up,
        totalsession: action.totalsession,
        sessionpersec: action.sessionpersec
      };
      case DASHBOARD_LIVE_MS:
        return {
          ...state,
          dash_grp_ms_down: action.dash_grp_ms_down,
          dash_grp_ms_up: action.dash_grp_ms_up,
        };
    case DASHBOARD_VLAN:
      return {
        ...state,
        dash_vl_grp_down: action.dash_vl_grp_down,
        dash_vl_grp_up: action.dash_vl_grp_up,
      };
      case DASHBOARD_VLAN_MAX:
      return {
        ...state,
        dash_vl_max_grp_up: action.dash_vl_max_grp_up,
        dash_vl_max_grp_down: action.dash_vl_max_grp_down
      };

    case IP_STATISTICS:
      return {
        ...state,
        ip_stat_ipv4_uplink: action.ipv4_uplink,
        ip_stat_ipv6_uplink: action.ipv6_uplink,
        ip_stat_ipv4_downlink: action.ipv4_downlink,
        ip_stat_ipv6_downlink: action.ipv6_downlink,
        ip_stat_time: action.time,
        v4_down_tot: action.v4_down_tot,
        v4_up_tot: action.v4_up_tot,
        v6_down_tot: action.v6_down_tot,
        v6_up_tot: action.v6_up_tot,
      };
    case PEAKUSAGEUP:
      return {
        ...state,
        peakup: action.up
      };
    case PEAKUSAGEDWON:
      return {
        ...state,
        peakdown: action.down
      };
    case PEAKUSAGETOTAL:
        return {
          ...state,
          peaktotal: action.total
        };
    default:
      return state;
  }
};

export default live;
