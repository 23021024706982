
export const LIVE_SESSION_DATA = 'LIVE_SESSION_DATA';

export const LIVE_USER_UPLINK_DATA = 'LIVE_USER_UPLINK_DATA';
export const LIVE_USER_DOWNLINK_DATA = 'LIVE_USER_DOWNLINK_DATA';
export const LIVE_USER_COUNT = 'LIVE_USER_COUNT';

export const LIVE_LINK_UPLINK_DATA = 'LIVE_LINK_UPLINK_DATA';
export const LIVE_LINK_DOWNLINK_DATA = 'LIVE_LINK_DOWNLINK_DATA';
export const VLAN_LINK_UPLINK_DATA = 'VLAN_LINK_UPLINK_DATA';
export const VLAN_LINK_DOWNLINK_DATA = 'VLAN_LINK_DOWNLINK_DATA';
export const VLAN_MAX_LINK_DOWNLINK_DATA = 'VLAN_MAX_LINK_DOWNLINK_DATA';
export const VLAN_MAX_LINK_UPLINK_DATA = 'VLAN_MAX_LINK_UPLINK_DATA';
export const LIVE_LINK_TOTAL_SESSION = 'LIVE_LINK_TOTAL_SESSION';
export const LIVE_LINK_SESSION_PER_SEC = 'LIVE_LINK_SESSION_PER_SEC';


export const URL_WATCH_TIMES = 'URL_WATCH_TIMES';
export const URL_WATCH_HTTP = 'URL_WATCH_HTTP';
export const URL_WATCH_HTTPS = 'URL_WATCH_HTTPS';
export const URL_WATCH_DNS = 'URL_WATCH_DNS';
export const URL_WATCH_HTTP_TABLE = 'URL_WATCH_HTTP_TABLE';
export const URL_WATCH_HTTPS_TABLE = 'URL_WATCH_HTTPS_TABLE';
export const URL_WATCH_DNS_TABLE = 'URL_WATCH_DNS_TABLE';
export const URL_WATCH_PAUSE = 'URL_WATCH_PAUSE';
export const PEAKUSAGEUP = 'PEAKUSAGEUP';
export const PEAKUSAGEDWON = 'PEAKUSAGEDWON';
export const PEAKUSAGETOTAL = 'PEAKUSAGETOTAL';


export const DASHBOARD_LIVE = 'DASHBOARD_LIVE';
export const DASHBOARD_LIVE_MS = 'DASHBOARD_LIVE_MS';
export const DASHBOARD_VLAN = 'DASHBOARD_VLAN';
export const DASHBOARD_VLAN_MAX = 'DASHBOARD_VLAN_MAX';
export const IP_STATISTICS='IP_STATISTICS';

