import {
  SQI_LIVE,
  SQI_HISTORY_APP,
  SQI_HISTORY_GRAPH,
  SQI_HISTORY_IP,
  SQI_TCP_RATE,
  SQI_TCP_RATE_PLOT,
  LATENCY,
  LATENCY_PLOT,
  LOADING1,
  LOADING2,
  LOADING3,
  LOADING4,
  LOADING5,
  LOADING6,
  FILTER,
  HEADER,
  COUNT,
  DELAY_PLOT,
  AVERAGE
} from "../constants/sqiTypes";

const initialData = {
  live: [],
  h_graph: [[], []],
  h_app: [[], []],
  h_ip: { node: [], link: [], colors: [] },
  t_rate: [],
  t_rate_plot: [[], [], [], []],
  latency: [],
  latency_plot: [[], []],
  delay_plot: [[], []],
  header: "",
  loading1: false,
  loading2: false,
  loading3: false,
  loading4: false,
  loading5: false,
  loading6: false,
  filters: {
    source: "Any",
    destination: "Any",
    dport: "Any",
    sport: "Any",
    vlan: "None",
    port: 0,
    smin: 0,
    smax: 10,
    application: 65535,
    sourceerror: false,
    deserror: false,
    sporterror: false,
    dporterror: false,
    selectedDate1: new Date(),
    selectedDate2: new Date(new Date().getTime() - 60000),
    ip_version: 0,
    sip: "0",
    sipr: "0",
    dip: "0",
    dipr: "0",
    selectedValue: "quick",
    quick: 1,
  },
  total:0,
  average:0
};

const sqi = (state = initialData, action) => {
  switch (action.type) {
    case SQI_LIVE:
      return {
        ...state,
        live: action.live,
      };
      case COUNT:
        return {
          ...state,
          total: action.count,
        };
    case SQI_HISTORY_GRAPH:
      return {
        ...state,
        h_graph: action.h_graph,
      };
    case SQI_HISTORY_APP:
      return {
        ...state,
        h_app: action.h_app,
      };
    case SQI_HISTORY_IP:
      return {
        ...state,
        h_ip: action.h_ip,
      };
    case SQI_TCP_RATE:
      return {
        ...state,
        t_rate: action.t_rate,
      };
    case SQI_TCP_RATE_PLOT:
      return {
        ...state,
        t_rate_plot: action.t_rate_plot,
      };
    case LATENCY:
      return {
        ...state,
        latency: action.latency,
      };
    case LATENCY_PLOT:
      return {
        ...state,
        latency_plot: action.latency_plot,
      };
       case DELAY_PLOT:
      return {
        ...state,
        delay_plot: action.delay_plot,
      };
    case LOADING1:
      return {
        ...state,
        loading1: action.loading1,
      };
    case LOADING2:
      return {
        ...state,
        loading2: action.loading2,
      };
    case LOADING3:
      return {
        ...state,
        loading3: action.loading3,
      };
    case LOADING4:
      return {
        ...state,
        loading4: action.loading4,
      };
    case LOADING5:
      return {
        ...state,
        loading5: action.loading5,
      };
    case LOADING6:
      return {
        ...state,
        loading6: action.loading6,
      };
    case FILTER:
      return {
        ...state,
        filters: action.filters,
      };
    case HEADER:
      return {
        ...state,
        header: action.header,
      };
      case AVERAGE:
      return {
        ...state,
        average: action.average,
      };
    default:
      return state;
  }
};

export default sqi;
