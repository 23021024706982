
import {
  REPORT_SOURCE,
  REPORT_APP,
  REPORT_DEST,
  REPORT_PORT,
  REPORT_SESSION,
  REPORT_COMPARISON,
  REPORT_STANDALONE,
  REPORT_SUMMARY,
  REPORT_FILTER,
  REPORT_BANDWIDTH,
  REPORT_BANDWIDTH_COMP_UPDATE,
  REPORT_BANDWIDTH_INIT,
  REPORT_COMPARISON_NO_DATA,
  REPORT_BANDWIDTH_COMP_NULL_FILTERS,
  REPORT_HEADER,
  WEEKLY_DAYS_ARRAY,
  FETCH_EMAIL_REPORT_PROFILES,
  FETCH_EMAIL_REPORT_EMAIL,
  FETCH_EMAIL_FILTERS,
  USER,
GROUP,
  DTR_HEADER,
  DTR_LOADING,
  DTR_TYPE
} from '../constants/report';


const initial = {
  src_data: [],
  des_data: [],
  app_data: [],
  port_data: [],
  session_data: [],
  report_filter: {
    uid: 0,
    stime: 0,
    etime: 0,
    sip: 0,
    dip: 0,
    dport: 0,
    vid: 0,
    prot: 0,
    aid: 0
  },
  comp_time: [],
  comp_up: [],
  comp_down: [],
  no_data: false,
  null_filters: false,
  stime: null,
  etime: null,
  mode: null,
  custom_mode: null,
  weekly_endDate: null,
  weekly_startDate: null,
  weekly_startTime: null,
  weekly_endTime: null,
  weekly_days: null,
  weekly_days_array: [],
  quick_mode: null,
  user: null,
  rmtuser: [],
  rmtgroup:[],
  source: null,
  destination: null,
  dport: null,
  vlan: null,
  port: null,
  app: null,
  standalone: {
    flag: false,
    up: [],
    down: []
  },
  emailprofile: [],
  emailprfileItem: [],
  emailfilter: [],

  up: [],
  down: [],

  dtr_header: '',
  loading: false,
  send: 0,
  rec: 0,
  total: 0,
  band_type: 0,
  total_session:0,
  max_total:0,
  count:0

};

const report = (state = initial, action) => {
  // console.log(action,state)
  switch (action.type) {
    case REPORT_SOURCE:
      return {
        ...state,
        src_data: action.src_data
      };
    case REPORT_DEST:
      return {
        ...state,
        des_data: action.des_data
      };
    case REPORT_APP:
      return {
        ...state,
        app_data: action.app_data
      };
    case REPORT_PORT:
      return {
        ...state,
        port_data: action.port_data
      };
    case REPORT_SESSION:
      return {
        ...state,
        session_data: action.session_data
      };
    case REPORT_FILTER:
      return {
        ...state,
        filter_data: action.report_filter
      };
    case REPORT_BANDWIDTH:
      return {
        ...state,
        comp_time: action.time,
        comp_up: [...state.comp_up, action.up],
        comp_down: [...state.comp_down, action.down]
      };
    case REPORT_BANDWIDTH_INIT:
      return {
        ...state,
        comp_time: [],
        comp_up: [],
        comp_down: []
      };
    case REPORT_COMPARISON_NO_DATA:
      return {
        ...state,
        no_data: action.status
      };
    case REPORT_BANDWIDTH_COMP_UPDATE:
      return {
        ...state,
        comp_up: action.up,
        comp_down: action.down
      };
    case REPORT_BANDWIDTH_COMP_NULL_FILTERS:
      return {
        ...state,
        null_filters: action.filters
      };
    case REPORT_HEADER:
      return {
        ...state,
        [action.name]: action.value
      };
    case WEEKLY_DAYS_ARRAY:
      return {
        ...state,
        weekly_days_array: [...action.arr]
      };
    case REPORT_STANDALONE:
      return {
        ...state,
        standalone: {
          flag: !state.standalone.flag,
          up: action.up,
          down: action.down
        }
      };
    case FETCH_EMAIL_REPORT_PROFILES:
      return {
        ...state,
        emailprofile: action.payload
      };
    case FETCH_EMAIL_REPORT_EMAIL:
      return {
        ...state,
        emailprfileItem: action.payload
      };
    case FETCH_EMAIL_FILTERS:
      return {
        ...state,
        emailfilter: action.payload
      }
    case DTR_HEADER:
      return {
        ...state,
        dtr_header: action.dtr_header,
        loading: action.loading,
        send: action.send,
        rec: action.rec,
        total: action.total,
        total_session:action.total_session,
        max_total:action.max_total,
        count:action.count
      };
    case DTR_LOADING:
      return {
        ...state,
        loading: action.loading
      };
    case USER:
      return {
        ...state,
        rmtuser: action.user
      };
      case GROUP:
      return {
        ...state,
        rmtgroup: action.group
      };
    case DTR_TYPE:
      return {
        ...state,
        band_type: action.band_type
      };
    default:
      return state;
  }
};

export default report;