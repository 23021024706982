
import {
  AUTHENTICATION,
  LOGINUSERNAME,
  LOGINUSERID,
  ONLINE
} from '../constants/loginData';

const initialSettings = {
  authentication: false,
  loginName: '',
  loginId: '',
  loginType: 0,
  online: null
};

const login = (state = initialSettings, action) => {
  // console.log(action,state)
  switch (action.type) {
    case AUTHENTICATION:
      return {
        ...state,
        authentication: action.authentication
      };
    case LOGINUSERNAME:
      return {
        ...state,
        loginName: action.loginName
      };
    case LOGINUSERID:
      return {
        ...state,
        loginId: action.loginId,
        loginType: action.loginType
      };
    case ONLINE:
      return {
        ...state,
        online: action.online
      };
    default:
      return state;
  }
};

export default login;

