import {
    CUSTOMUSERTYPES
  } from '../constants/settingsType';

  
const initialState = {
    custom_user_type : []
};

const customusertypes = (state = initialState, action) => {
    switch (action.type) {
        case CUSTOMUSERTYPES:
          return {
            ...state,
            custom_user_type: action.custom_user_type
          };
        default:
          return state;
    }
};

export default customusertypes;