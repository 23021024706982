import {
    RADIUS_SERVER,

} from '../constants/radiusServer';


const initialState = {
    radius_server: []
};

const radius_server = (state = initialState, action) => {
    switch (action.type) {
        case RADIUS_SERVER:
            return {
                ...state,
                radius_server: action.radius_server
            };

            default:
                return state;
    }
};

export default radius_server;