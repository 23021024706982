import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { Route, Redirect } from "react-router-dom";
import loadable from "react-loadable";
import LoadingComponent from "components/Loading";
import { Auth } from "actions/loginActions";
import Notification from "components/Notification";

// = styles =
// 3rd
import "styles/bootstrap/bootstrap.scss";
// custom
import "styles/layout.scss";
import "styles/theme.scss";
import "styles/ui.scss";
import "styles/table.scss";

import lightTheme from "./themes/lightTheme";
import darkTheme from "./themes/darkTheme";
import grayTheme from "./themes/grayTheme";
import "../routes/user/routes/login/components/Login.css";
import Logo from "../assets/images/paraqum_logo/pq_logo.png";

let MainApp = loadable({
  loader: () => import("components/Layout/AppLayout/MainApp"),
  loading: LoadingComponent,
});
let Account = loadable({
  loader: () => import("routes/user/"),
  loading: LoadingComponent,
});
let Page = loadable({
  loader: () => import("./404_page"),
  loading: LoadingComponent,
});

class App extends Component {
  componentDidMount() {
    this.props.authentication();
  }
  componentDidUpdate() {
    const { auth } = this.props;
    if (!auth) {
      return <Redirect to={"user/login"} />;
    } else {
      return <Redirect to={"app/dashboard/link_summary"} />;
    }
  }
  render() {
    const {
      match,
      location,
      layoutBoxed,
      navCollapsed,
      navBehind,
      fixedHeader,
      sidebarWidth,
      theme,
      auth,
      type,
    } = this.props;
    let materialUITheme;
    switch (theme) {
      case "gray":
        materialUITheme = grayTheme;
        break;
      case "dark":
        materialUITheme = darkTheme;
        break;
      default:
        materialUITheme = lightTheme;
    }

    const isRoot = location.pathname === "/" ? true : false;
    if (isRoot) {
      if (auth) {
        return <Redirect to={"app/dashboard/link_summary"} />;
      } else {
        return <Redirect to={"user/login"} />;
      }
    }
    const isLoginPage = window.location.href.includes("/user/login");
    return (
      <MuiThemeProvider theme={materialUITheme}>
        {isLoginPage && (
          <section className="logo text-center">
            <img
              alt="product"
              src={Logo}
              style={{
                height: "15%",
                width: "15%",
                zIndex: 100,
                marginTop: 50,
                marginBottom: -200,
              }}
            />
          </section>
        )}
        <div id="app-inner" className={isLoginPage ? "login-container" : ""}>
          <div className="preloaderbar hide">
            <span className="bar" />
          </div>
          <div
            style={{ width: `100px !important` }}
            className={classnames("app-main h-100", {
              "fixed-header": fixedHeader,
              "nav-collapsed": navCollapsed,
              "nav-behind": navBehind,
              "layout-boxed": layoutBoxed,
              "theme-gray": theme === "gray",
              "theme-dark": theme === "dark",
              "sidebar-sm": sidebarWidth === "small",
              "sidebar-lg": sidebarWidth === "large",
            })}
          >
            <Route path={`${match.url}app`} component={MainApp} />
            <Route path={`${match.url}user`} component={Account} />
            <Route path={"404"} component={Page} />
          </div>
          <Notification />
        </div>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    layoutBoxed: state.settings.layoutBoxed,
    navCollapsed: state.settings.navCollapsed,
    navBehind: state.settings.navBehind,
    fixedHeader: state.settings.fixedHeader,
    sidebarWidth: state.settings.sidebarWidth,
    theme: state.settings.theme,
    auth: state.login.authentication,
    type: parseInt(state.login.loginType),
  };
};
const mapDispatchToProps = (dispatch) => ({
  authentication: () => {
    dispatch(Auth());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
