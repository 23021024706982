import axios from 'axios';
import * as types from '../constants/appsrvsTypes';
import { BASE_URL } from '../constants/baseURL';
import randomColor from 'randomcolor';


export const get_applications = () => {
    return dispatch => {
        axios
            .get(BASE_URL + 'api/applicationlist', { withCredentials: true })
                .then(res => {
                console.log(res.data);
                dispatch(applist(res.data));
                let color = [];
                for (var i in res.data) {
                    color.push(randomColor({ luminosity: 'bright' }))
                }
                axios
                    .get(BASE_URL + 'api/servicelist', { withCredentials: true })
                    .then(res => {
                        // console.log(res);
                        dispatch(srvslist(res.data));
                        for (var i in res.data) {
                            color.push(randomColor({ luminosity: 'bright' }))
                        }
                        // dispatch(appcolorlist(color));

                    })

                    .catch(err => {
                        console.log(err);
                    });

            })
            .catch(err => {
                console.log(err);
            });

        // setTimeout(() => {
        //     dispatch(get_services());
        // }, 50);
    };
};

export const get_services = () => {
    return dispatch => {
        axios
            .get(BASE_URL + 'api/servicelist', { withCredentials: true })
            .then(res => {
                // console.log(res);
                dispatch(srvslist(res.data));

            })
            .catch(err => {
                console.log(err);
            });
    };
};

export const get_app_sigProf_sigCat_service_list = () => {

    let color=["#63b598"]
    let filtered_app = [];
    let all_filtered_app_id_list=[];
    return dispatch => {
        axios
            .get(BASE_URL + 'api/app_sigProf_sigCat_service_list', { withCredentials: true }) 
            .then(res => {
            //    console.log(res)
                
                    for (var i in res.data) {
                        if(res.data[i]!==''){
                            filtered_app.push({appid:parseInt(i),appname:res.data[i]})
                            all_filtered_app_id_list.push(parseInt(i))
                        }else if( res.data[i]!==null){
                            filtered_app.push({appid:parseInt(i),appname:"-"})
                            all_filtered_app_id_list.push(parseInt(i))
                        }
                        color.push(randomColor({ luminosity: 'bright' }))
                    }
                     
            
                    dispatch(filtered_app_list(filtered_app));
                    dispatch(filtered_app_id_list(all_filtered_app_id_list));
                dispatch(appcolorlist(color));
                dispatch(app_sigProf_sigCat_service_list(res.data));

            })
            .catch(err => {
                console.log(err);

            });
    };
};

export const get_app_sigProf_sigCat_service_list_search = (str) => {
    
    return dispatch => {
        axios
            .get(BASE_URL + 'api/get_report_apps_search/'+ str, { withCredentials: true })
            .then(res => {
               
                dispatch(appsearch(res.data));

            })
            .catch(err => {
                console.log(err);
            });
    };
};

export const get_app_sigProf_sigCat_service_list_search_app_Profile = (str) => {
    
    return dispatch => {
        axios
            .get(BASE_URL + 'api/get_rule_apps_search/'+ str, { withCredentials: true })
            .then(res => {
               
                dispatch(appsprofearch(res.data));

            })
            .catch(err => {
                console.log(err);
            });
    };
};

export const get_app_sigProf_sigCat_service_list_search_Rule_stat = (str) => {
    
    return dispatch => {
        axios
            .get(BASE_URL + 'api/get_rule_apps_search/'+ str, { withCredentials: true })
            .then(res => {
               
                dispatch(appsearch(res.data));

            })
            .catch(err => {
                console.log(err);
            });
    };
};

export const get_app_sigProf_sigCat_service_list_search_by_id = (str) => {
    
    return dispatch => {
        axios
            .get(BASE_URL + 'api/app_sigProf_sigCat_service_list_search/id/'+ str, { withCredentials: true })
            .then(res => {
               console.log(res.data)
                dispatch(appsearch(res.data));

            })
            .catch(err => {
                console.log(err);
            });
    };
};



export const get_app_sigProf_sigCat_service_list_Callback = (callback) => {
    let color = ["#63b598"];
    let filtered_app = [];
    let all_filtered_app_id_list = [];
    return dispatch => {
        axios
            .get(BASE_URL + 'api/app_sigProf_sigCat_service_list', { withCredentials: true })
            .then(res => {
                for (var i in res.data) {
                    if (res.data[i] !== '') {
                        filtered_app.push({ appid: parseInt(i), appname: res.data[i] })
                        all_filtered_app_id_list.push(parseInt(i))
                    }
                    color.push(randomColor({ luminosity: 'bright' }))
                }


                dispatch(filtered_app_list(filtered_app));
                dispatch(filtered_app_id_list(all_filtered_app_id_list));
                dispatch(appcolorlist(color));
                dispatch(app_sigProf_sigCat_service_list(res.data));
                callback();

            })
            .catch(err => {
                // console.log(err);
            });
    };
};


export function applist(application) {
    return { type: types.APP_LIST, application };
};
export function srvslist(services) {
    return { type: types.SRVS_LIST, services };
};
export function app_sigProf_sigCat_service_list(app_sigProf_sigCat_service) {
    return { type: types.APP_SIGPROF_SIGCAT_SERV_LIST, app_sigProf_sigCat_service };
};
export function filtered_app_list(filtered_app) {
    return { type: types.FILTERED_APP, filtered_app };
};
export function filtered_app_id_list(filtered_app_id) {
    return { type: types.FILTERED_APP_ID_LIST, filtered_app_id };
};
export function appcolorlist(color) {
    return { type: types.APP_COLOR_LIST, color };
};

export function appcolorlistupdate(id, color) {
    return { type: types.APP_COLOR_LIST_UPDATE, id, color };
};

export function appsearch(search) {
    return { type: types.APP_SEARCH, search };
};
export function appsprofearch(search_app_prof) {
    return { type: types.APP_PROF_SEARCH, search_app_prof };
};