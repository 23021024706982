import {FETCH_DEVICE_SCHEDS_DATA,
FETCH_DEVICE_ACTION_PROFILE,
FETCH_DEVICE_ACTION_PROFILE_ITEM,
FETCH_DEVICE_IP_CONFIG_DATA,
FETCH_ACTION_DEVICES,
FETCH_INACTION_DEVICES} from '../constants/device_monitor'


const initialState = {
    deviceScheds : [],
    deviceActionProf:[],
    deviceActionProfItem: [] ,
    deviceIpConfig : [],
    activeDevices : [],
    inactiveDevice : []
}

const device_Sched_reducers = (state=initialState,action)=>{


    if(action.type === FETCH_DEVICE_SCHEDS_DATA){
        return{
            ...state,
            deviceScheds : action.payload
            
        }
    }else if(action.type === FETCH_DEVICE_ACTION_PROFILE ){
        return{
            ...state,
            deviceActionProf : action.payload
        }

    }else if(action.type=== FETCH_DEVICE_ACTION_PROFILE_ITEM){
        return{
            ...state,
            deviceActionProfItem : action.payload
        }
    }else if(action.type === FETCH_DEVICE_IP_CONFIG_DATA){
        return{
            ...state,
            deviceIpConfig : action.payload
        }

    }else if(action.type === FETCH_ACTION_DEVICES){
        return{
            ...state,
            activeDevices : action.payload
        }

    }return state

}

export default device_Sched_reducers;
