
import {
    SESSION_FILTER_CHIPDATA,
    SESSION_FILTER_CHECKED,
    SESSION_FILTER_NEWVAL,
    SESSION_FILTER_INPUTVAL
} from '../constants/actionTypes';

const initialSettings = {
    chipData: [],
    checked: [],
    newval: -1,
    inputval:''};

const session_filter = (state = initialSettings, action) => {
    // console.log(action,state)
    switch (action.type) {
        case SESSION_FILTER_CHIPDATA:
          return {
            ...state,
            chipData: action.chipdata
          };
        case SESSION_FILTER_CHECKED:
          return {
            ...state,
            checked: action.checked
          };
        case SESSION_FILTER_NEWVAL:
          return {
            ...state,
            newval: action.newval
          };
        case SESSION_FILTER_INPUTVAL:
          return {
            ...state,
            inputval: action.inputval
          };
        default:
          return state;
    }
};

export default session_filter;

