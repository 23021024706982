import {
    DHCP,
    DHCP_USERS
} from '../constants/dhcp';


const initialState = {
    dhcp: [],
    dhcp_users: []
};

const dhcp = (state = initialState, action) => {
    switch (action.type) {
        case DHCP:
            return {
                ...state,
                dhcp: action.dhcp
            };
        case DHCP_USERS:
            return {
                ...state,
                dhcp_users: action.dhcp_users
            };
       
        default:
            return state;
    }
};

export default dhcp;